export const LabelConstants = {
  PETROMIN: "PETROMIN",
  SORT_BY: "SORT_BY",
  PRICE: "PRICE",
  LISTING_DATE: "LISTING_DATE",
  FILTER_BY: "FILTER_BY",
  COLOR: "COLOR",
  ROW_PER_PAGE: "ROW_PER_PAGE",
  INTERIOR_COLOR: "INTERIOR_COLOR",
  EXTERIOR_COLOR: "EXTERIOR_COLOR",
  FEATURES: "FEATURES",
  KILOMETERS: "KILOMETERS",
  SAR: "SAR",
  ASC: "ASC",
  DESC: "DESC",
  YEAR: "YEAR",
  MAKE: "MAKE",
  MODEL: "MODEL",
  TRIM_IDENTIFIER: "TRIM_IDENTIFIER",
  STYLE: "STYLE",
  FILTER: "FILTER",
  OWNERSHIP: "OWNERSHIP",
  TRANSMISSION: "TRANSMISSION",
  FUEL_TYPE: "FUEL_TYPE",
  CITY: "CITY",
  STATE: "STATE",
  BODY_TYPES: "BODY_TYPES",
  NOTIFY_ME: "NOTIFY_ME",
  SELECT: "SELECT",
  SELECT_ASSIGN: "SELECT_ASSIGN",
  ASSIGN: "ASSIGN",
  VEHICLE_ADDED_TO_QC: "VEHICLE_ADDED_TO_QC",
  DEALER_REGISTRATION: "DEALER_REGISTRATION",
  DEALER_APPLICATION: "DEALER_APPLICATION",
  DEALER_NAME: "DEALER_NAME",
  OWNER_NAME: "OWNER_NAME",
  REGISTRATION_ID: "REGISTRATION_ID",
  PRIMARY_CONTACT_FIRSTNAME: "PRIMARY_CONTACT_FIRSTNAME",
  PRIMARY_CONTACT_LASTNAME: "PRIMARY_CONTACT_LASTNAME",
  PRIMARY_CONTACT_NUMBER: "PRIMARY_CONTACT_NUMBER",
  PRIMARY_EMAIL_ID: "PRIMARY_EMAIL_ID",
  SECONDARY_CONTACT_NUMBER: "SECONDARY_CONTACT_NUMBER",
  SECONDARY_EMAIL_ID: "SECONDARY_EMAIL_ID",
  ENTER_ADDRESS_LINE1: "ENTER_ADDRESS_LINE1",
  ENTER_ADDRESS_LINE2: "ENTER_ADDRESS_LINE2",
  ENTER_ADDRESS_LINE3: "ENTER_ADDRESS_LINE3",
  SELECT_STATE: "SELECT_STATE",
  SELECT_CITY: "SELECT_CITY",
  PIN_CODE: "PIN_CODE",
  SELECT_MAKE: "SELECT_MAKE",
  FRM_ERR_MSG_NAME: "FRM_ERR_MSG_NAME",
  FRM_ERR_MSG_OWNER_NAME: "FRM_ERR_MSG_OWNER_NAME",
  FRM_ERR_MSG_REGISTRATION_ID: "FRM_ERR_MSG_REGISTRATION_ID",
  FRM_ERR_MSG_PRIMARY_FIRST_NAME: "FRM_ERR_MSG_PRIMARY_FIRST_NAME",
  FRM_ERR_MSG_PRIMARY_LAST_NAME: "FRM_ERR_MSG_PRIMARY_LAST_NAME",
  FRM_ERR_MSG_ZIP: "FRM_ERR_MSG_ZIP",
  FRM_ERR_MSG_ZIP_NUMBER: "FRM_ERR_MSG_ZIP_NUMBER",
  FRM_ERR_MSG_CITY: "FRM_ERR_MSG_CITY",
  FRM_ERR_MSG_MAX30_LETTER: "FRM_ERR_MSG_MAX30_LETTER",
  FRM_ERR_MSG_MAX250_LETTER: "FRM_ERR_MSG_MAX250_LETTER",
  FRM_ERR_MSG_ONLY_LETTER: "FRM_ERR_MSG_ONLY_LETTER",
  FRM_ERR_MSG_ONLY_LETTER_AND_DIGITS: "FRM_ERR_MSG_ONLY_LETTER_AND_DIGITS",
  FRM_ERR_MSG_MAX30_CHARACTER: "FRM_ERR_MSG_MAX30_CHARACTER",
  FRM_ERR_MSG_MAX500_CHARACTER: "FRM_ERR_MSG_MAX500_CHARACTER",
  FRM_ERR_MSG_MAX100_CHARACTER: "FRM_ERR_MSG_MAX100_CHARACTER",
  FRM_ERR_MSG_EMAIL: "FRM_ERR_MSG_EMAIL",
  FRM_ERR_MSG_EMAIL_FORMAT: "FRM_ERR_MSG_EMAIL_FORMAT",
  FRM_ERR_MSG_ADDRESS: "FRM_ERR_MSG_ADDRESS",
  FRM_ERR_MSG_PRIMARY_PHONE_NUMBER: "FRM_ERR_MSG_PRIMARY_PHONE_NUMBER",
  FRM_ERR_MSG_MAX9_DIGITS: "FRM_ERR_MSG_MAX9_DIGITS",
  ERR_MSG_NAME: "ERR_MSG_NAME",
  ERR_MSG_OWNER_NAME: "ERR_MSG_OWNER_NAME",
  ERR_MSG_REGISTRATION_ID: "ERR_MSG_REGISTRATION_ID",
  ERR_MSG_PRIMARY_FIRST_NAME: "ERR_MSG_PRIMARY_FIRST_NAME",
  ERR_MSG_PRIMARY_LAST_NAME: "ERR_MSG_PRIMARY_LAST_NAME",
  ERR_MSG_PRIMARY_CONTACT: "ERR_MSG_PRIMARY_CONTACT",
  ERR_MSG_PRIMARY_EMAIL_ID: "ERR_MSG_PRIMARY_EMAIL_ID",
  ERR_MSG_ADDRESS_LINE1: "ERR_MSG_ADDRESS_LINE1",
  ERR_MSG_ADDRESS_LINE2: "ERR_MSG_ADDRESS_LINE2",
  ERR_MSG_ADDRESS_LINE3: "ERR_MSG_ADDRESS_LINE3",
  ERR_MSG_CITY: "ERR_MSG_CITY",
  ERR_MSG_STATE: "ERR_MSG_STATE",
  ERR_MSG_PIN_CODE: "ERR_MSG_PIN_CODE",
  ERR_MSG_SECONDARY_CONTACT: "ERR_MSG_SECONDARY_CONTACT",
  ERR_MSG_SECONDARY_EMAIL_ID: "ERR_MSG_SECONDARY_EMAIL_ID",
  ERROR_DEALER_REGISTRATION_ID_EXIST: "ERROR_DEALER_REGISTRATION_ID_EXIST",
  ERROR_DEALER_PRIMARY_EMAIL_EXIST: "ERROR_DEALER_PRIMARY_EMAIL_EXIST",
  ERROR_DEALER_SECONDARY_EMAIL_EXIST: "ERROR_DEALER_SECONDARY_EMAIL_EXIST",
  ERROR_DEALER_PRIMARY_PHONE_EXIST: "ERROR_DEALER_PRIMARY_PHONE_EXIST",
  ERROR_DEALER_SECONDARY_PHONE_EXIST: "ERROR_DEALER_SECONDARY_PHONE_EXIST",
  DEALER_APPLICATION_SAVED: "DEALER_APPLICATION_SAVED",
  ERROR_DEALER_SAVE: "ERROR_DEALER_SAVE",
  LBL_NOTES: "LBL_NOTES",
  LBL_APPROVE: "LBL_APPROVE",
  LBL_REJECT: "LBL_REJECT",
  LBL_VIEW_INSPECTION_REPORT: "LBL_VIEW_INSPECTION_REPORT",
  LBL_INSPECTION_STATUS: "LBL_INSPECTION_STATUS",
  LBL_REJECTION_REASON: "LBL_REJECTION_REASON",
  LBL_REJECTED: "LBL_REJECTED",
  LBL_HISTORY: "LBL_HISTORY",
  LBL_ID: "LBL_ID",
  LBL_OK: "LBL_OK",
  LBL_CANCEL: "LBL_CANCEL",
  LBL_SUBMIT: "LBL_SUBMIT",
  FRM_INFO_MSG_APPROVE_QC_STATUS: "FRM_INFO_MSG_APPROVE_QC_STATUS",
  VEHICLE_MSG_APPROVE_QC_STATUS: "VEHICLE_MSG_APPROVE_QC_STATUS",
  FRM_INFO_MSG_STATUS_SUBMIT: "FRM_INFO_MSG_STATUS_SUBMIT",
  LBL_VEHICLE_IMAGE: "LBL_VEHICLE_IMAGE",
  LBL_INSPECTION_REPORT: "LBL_INSPECTION_REPORT",
  LBL_QC_STATUS: "LBL_QC_STATUS",
  FRM_ERR_MSG_FAIL_QC_STATUS: "FRM_ERR_MSG_FAIL_QC_STATUS",
  FRM_ERR_MSG_SOMETHING_WRONG: "FRM_ERR_MSG_SOMETHING_WRONG",
  FRM_ERR_MSG_APPROVE_IMAGES: "FRM_ERR_MSG_APPROVE_IMAGES",
  FRM_ERR_MSG_REQUIRED_FIELD: "FRM_ERR_MSG_REQUIRED_FIELD",
  FRM_SUCCESS_MSG_QC_STATUS_UPDATED: "FRM_SUCCESS_MSG_QC_STATUS_UPDATED",
  FILTER_ASSIGNEE_NAME: "FILTER_ASSIGNEE_NAME",
  HISTORY: "HISTORY",
  ID: "ID",
  SELECT_TO_ASSIGN: "SELECT_TO_ASSIGN",
  RE_ASSIGN: "RE_ASSIGN",
  INSPECTION_DATE: "INSPECTION_DATE",
  INSPECTION_COMMENT: "INSPECTION_COMMENT",
  INSPECTION_STATUS: "INSPECTION_STATUS",
  QC_DATE: "QC_DATE",
  QC_COMMENT: "QC_COMMENT",
  QC_STATUS: "QC_STATUS",
  LBL_LOGIN: "LBL_LOGIN",
  LBL_PASSWORD_CHANGED_SUCCESSFULLY: "LBL_PASSWORD_CHANGED_SUCCESSFULLY",
  PLEASE_SELECT_USER_TYPE: "PLEASE_SELECT_USER_TYPE",
  USER_TYPE: "USER_TYPE",
  SELECT_USER_TYPE: "SELECT_USER_TYPE",
  ENTER_FIRST_NAME: "ENTER_FIRST_NAME",
  ENTER_LAST_NAME: "ENTER_LAST_NAME",
  ENTER_MOBILE_NUMBER: "ENTER_MOBILE_NUMBER",
  ENTER_ALTERNATE_MOBILE: "ENTER_ALTERNATE_MOBILE",
  EMAIL_REQUIRED: "EMAIL_REQUIRED",
  EMAIL_FORMAT: "EMAIL_FORMAT",
  SELECT_COMMUNICATION: "SELECT_COMMUNICATION",
  SELECT_ROLE: "SELECT_ROLE",
  FIRST_NAME: "FIRST_NAME",
  MIDDLE_NAME: "MIDDLE_NAME",
  LAST_NAME: "LAST_NAME",
  MOBILE_NUMBER: "MOBILE_NUMBER",
  ALTERNATE_NUMBER: "ALTERNATE_NUMBER",
  ALTERNATE_EMAIL: "ALTERNATE_EMAIL",
  MOBILE: "MOBILE",
  EMAIL: "EMAIL",
  WHATSAPP: "WHATSAPP",
  POSITION: "POSITION",
  ROLES: "ROLES",
  CRM_ADMIN: "CRM_ADMIN",
  QC_ADMIN: "QC_ADMIN",
  QC_ANALYST: "QC_ANALYST",
  CRM_SALESPERSON: "CRM_SALESPERSON",
  BACK: "BACK",
  CREATE: "CREATE",
  USER_CREATION: "USER_CREATION",
  PREFERRED_COMMUNICATION: "PREFERRED_COMMUNICATION",
  SELECT_TYPE_OF_USER: "SELECT_TYPE_OF_USER",
  DMS_RESPONSE: "DMS_RESPONSE",
  DMS_QUERY: "DMS_QUERY",
  BTN_SEND: "BTN_SEND",
  FLTR_VEHICLE_CREATED: "FLTR_VEHICLE_CREATED",
  FLTR_SALES_PERSON: "FLTR_SALES_PERSON",
  FLTR_VEHICLE_AGE: "FLTR_VEHICLE_AGE",
  EDIT_VEHICLE: "EDIT_VEHICLE",
  RAISE_QUERY: "RAISE_QUERY",
  LEADS: "LEADS",
  INSPECTION_APPROVED: "INSPECTION_APPROVED",
  INSPECTION_REJECTED: "INSPECTION_REJECTED",
  ACTIVE: "ACTIVE",
  DEACTIVATED: "DEACTIVATED",
  DELETED: "DELETED",
  TOTAL: "TOTAL",
  VEHICLE: "VEHICLE",
  ASSIGN_VEHICLE: "ASSIGN_VEHICLE",
  LEADS_VEHICLE: "LEADS_VEHICLE",
  DOWNLOAD: "DOWNLOAD",
  ASSIGN_RECORDS: "ASSIGN_RECORDS",
  NAME: "NAME",
  VEHICLE_ASSIGN: "VEHICLE_ASSIGN",
  VEHICLES_READY_FOR_INSPECTION: "VEHICLES_READY_FOR_INSPECTION",
  VEHICLES_READY_TO_LIST: "VEHICLES_READY_TO_LIST",
  VEHICLE_QC_REJECTED: "VEHICLE_QC_REJECTED",
  VEHICLES_WITH_LEADS: "VEHICLES_WITH_LEADS",
  TOTAL_LEADS_ASSIGNED: "TOTAL_LEADS_ASSIGNED",
  ACTION: "ACTION",
  DMS_USER_CREATION: "DMS_USER_CREATION",
  DEALER_ID: "DEALER_ID",
  ZIP_CODE: "ZIP_CODE",
  ADDRESS_LINE_1: "ADDRESS_LINE_1",
  ADDRESS_LINE_2: "ADDRESS_LINE_2",
  ANALYST_VIEW: "ANALYST_VIEW",
  BTN_DOWNLOAD: "BTN_DOWNLOAD",
  TBL_HEADER_NAME: "TBL_HEADER_NAME",
  TBL_HEADER_VEHICLE_ASSIGN: "TBL_HEADER_VEHICLE_ASSIGN",
  TBL_HEADER_VEHICLE_INPROGRESS: "TBL_HEADER_VEHICLE_INPROGRESS",
  TBL_HEADER_VEHICLE_REJECT: "TBL_HEADER_VEHICLE_REJECT",
  TBL_HEADER_VEHICLE_PASSED: "TBL_HEADER_VEHICLE_PASSED",
  TBL_HEADER_AVAILABILITY_VIEW: "TBL_HEADER_AVAILABILITY_VIEW",
  TBL_HEADER_ACTION: "TBL_HEADER_ACTION",
  TBL_CELL_OCCUPIED: "TBL_CELL_OCCUPIED",
  TBL_CELL_AVAILABLE: "TBL_CELL_AVAILABLE",
  LBL_ASSIGN_VEHICLE_TO: "LBL_ASSIGN_VEHICLE_TO",
  LBL_ASSIGN_TO: "LBL_ASSIGN_TO",
  TBL_HEADER_DATE: "TBL_HEADER_DATE",
  TBL_HEADER_STATUS: "TBL_HEADER_STATUS",
  TBL_HEADER_USER: "TBL_HEADER_USER",
  LBL_VEHICLE_ASSIGN: "LBL_VEHICLE_ASSIGN",
  LBL_VEHICLE_INPROGRESS: "LBL_VEHICLE_INPROGRESS",
  LBL_VEHICLE_REJECT: "LBL_VEHICLE_REJECT",
  LBL_VEHICLE_PASSED: "LBL_VEHICLE_PASSED",
  LBL_VEHICLE_UNASSIGN: "LBL_VEHICLE_UNASSIGN",
  LBL_MASTER_DATA_CONFIGURATION: "LBL_MASTER_DATA_CONFIGURATION",
  LBL_NOT_APPLICABLE: "LBL_NOT_APPLICABLE",
  TOP_CITIES: "TOP_CITIES",
  TOP_BRANDS: "TOP_BRANDS",
  INSPECTION_FEES: "INSPECTION_FEES",
  VEHICLE_DELISTING_DAYS: "VEHICLE_DELISTING_DAYS",
  EDIT: "EDIT",
  CURRENT_VALUE: "CURRENT_VALUE",
  LAST_UPDATED_ON: "LAST_UPDATED_ON",
  LBL_INSPECTION_FEE_DETAILS: "LBL_INSPECTION_FEE_DETAILS",
  ENABLE: "ENABLE",
  PER_VEHICLE: "PER_VEHICLE",
  SAVE: "SAVE",
  LBL_INSPECTION_FEES: "LBL_INSPECTION_FEES",
  LBL_VEHICLE_ACCEPTING_CRITERIA: "LBL_VEHICLE_ACCEPTING_CRITERIA",
  LBL_VEHICLE_AGE: "LBL_VEHICLE_AGE",
  LBL_ODOMETER_READING: "LBL_ODOMETER_READING",
  LBL_VEHICLE_DELISTING_DAYS: "LBL_VEHICLE_DELISTING_DAYS",
  TBL_HEADER_LEAD_ASSIGNMENT_ID: "TBL_HEADER_LEAD_ASSIGNMENT_ID",
  TBL_HEADER_QUERY: "TBL_HEADER_QUERY",
  LBL_QUERY_RESPONSE: "LBL_QUERY_RESPONSE",
  USERS_VIEW: "USERS_VIEW",
  DEACTIVATE: "DEACTIVATE",
  REACTIVATE: "REACTIVATE",
  NA: "NA",
  CREATE_USER: "CREATE_USER",
  USER_ID: "USER_ID",
  FULL_NAME: "FULL_NAME",
  STATUS: "STATUS",
  CREATED_DATE: "CREATED_DATE",
  DEACTIVATED_DATE: "DEACTIVATED_DATE",
  REJECTION_REASON: "REJECTION_REASON",
  ADDITIONAL_INFORMATION: "ADDITIONAL_INFORMATION",
  PENDING_REQUEST: "PENDING_REQUEST",
  VIEW: "VIEW",
  CONFIRM_APPROVE_REQUEST: "CONFIRM_APPROVE_REQUEST",
  CONFIRM_APPROVE_REQUEST_MSG: "CONFIRM_APPROVE_REQUEST_MSG",
  SELECT_REJECTION_REASON: "SELECT_REJECTION_REASON",
  REJECTION_REASON_REQUIRED: "REJECTION_REASON_REQUIRED",
  LEAD_ID: "LEAD_ID",
  LEAD_RECEIVE_DATE: "LEAD_RECEIVE_DATE",
  LEAD_SHARED_TO_DEALER_ON: "LEAD_SHARED_TO_DEALER_ON",
  LEAD_CATEGORY: "LEAD_CATEGORY",
  LEAD_SOURCE: "LEAD_SOURCE",
  BUYER: "BUYER",
  ASSIGNED_TO: "ASSIGNED_TO",
  VEHICLE_ID: "VEHICLE_ID",
  LBL_USER_UPDATE: "LBL_USER_UPDATE",
  BTN_UPDATE: "BTN_UPDATE",
  REQUESTED_DATE: "REQUESTED_DATE",
  DEALER_ADMIN: "DEALER_ADMIN",
  DMS_SALESPERSON: "DMS_SALESPERSON",
  PROFILE_CHANGE_REQ: "PROFILE_CHANGE_REQ",
  PROFILE: "PROFILE",
  LOGOUT: "LOGOUT",
  DMS_USER_UPDATE: "DMS_USER_UPDATE",
  VEHICLE_ASSIGNED_PENDING_ACTION: "VEHICLE_ASSIGNED_PENDING_ACTION",
  VEHICLE_STATUS: "VEHICLE_STATUS",
  VEHICLE_ASSIGNED_ON: "VEHICLE_ASSIGNED_ON",
  REQUESTED_VALUE: "REQUESTED_VALUE",
  COMMENTS: "COMMENTS",
  SALESPERSON_PROFILE_CHANGE: "SALESPERSON_PROFILE_CHANGE",
  PROFILE_CHANGE: "PROFILE_CHANGE",
  ONBOARD_VEHICLE: "ONBOARD_VEHICLE",
  BULK_LIST: "BULK_LIST",
  PLEASE_SELECT_XLS_FILE: "PLEASE_SELECT_XLS_FILE",
  UPLOAD_VEHICLE: "UPLOAD_VEHICLE",
  ONBOARD_MULTIPLE_VEHICLES: "ONBOARD_MULTIPLE_VEHICLES",
  UPLOAD_XLS_FILES: "UPLOAD_XLS_FILES",
  DOWNLOAD_SAMPLE_EXCEL: "DOWNLOAD_SAMPLE_EXCEL",
  VIN: "VIN",
  VIN_DESCRIPTION: "VIN_DESCRIPTION",
  MANUFACTURING_YEAR: "MANUFACTURING_YEAR",
  VEHICLE_MAKE: "VEHICLE_MAKE",
  VEHICLE_MODEL: "VEHICLE_MODEL",
  TRIM: "TRIM",
  TRIM_DESCRIPTION: "TRIM_DESCRIPTION",
  CONDITION: "CONDITION",
  CONDITION_DESCRIPTION: "CONDITION_DESCRIPTION",
  ODOMETER_READING: "ODOMETER_READING",
  SELLER_ID: "SELLER_ID",
  DEALER_PRIMARY_ID: "DEALER_PRIMARY_ID",
  PREFERRED_INSPECTION_DATE: "PREFERRED_INSPECTION_DATE",
  INSPECTION_TIME: "INSPECTION_TIME",
  PREFERRED_INSPECTION_SLOT: "PREFERRED_INSPECTION_SLOT",
  INSPECTION_LOCATION: "INSPECTION_LOCATION",
  PREFERRED_INSPECTION_LOCATION: "PREFERRED_INSPECTION_LOCATION",
  CITY_DESCRIPTION: "CITY_DESCRIPTION",
  OPTIONAL: "OPTIONAL",
  ASKING_PRICE_DESCRIPTION: "ASKING_PRICE_DESCRIPTION",
  ASKING_PRICE: "ASKING_PRICE",
  LISTING_SUMMARY: "LISTING_SUMMARY",
  REMARKS: "REMARKS",
  BROWSE_FILE: "BROWSE_FILE",
  UPLOAD: "UPLOAD",
  THANK_YOU: "THANK_YOU",
  UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
  UPLOAD_FAILURE: "UPLOAD_FAILURE",
  OK: "OK",
  VIEW_MORE: "VIEW_MORE",
  BULK_UPLOAD_REPORT: "BULK_UPLOAD_REPORT",
  BATCH_ID: "BATCH_ID",
  START_DATE: "START_DATE",
  END_DATE: "END_DATE",
  SUBMISSION_DATE: "SUBMISSION_DATE",
  BATCH_STATUS: "BATCH_STATUS",
  TOTAL_RECORDS: "TOTAL_RECORDS",
  FAILED: "FAILED",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  BULK_REPORT_BATCH: "BULK_REPORT_BATCH",
  LISTING_ID: "LISTING_ID",
  MODEL_YEAR: "MODEL_YEAR",
  PROCESSED_DATE: "PROCESSED_DATE",
  LOCATION_CODE: "LOCATION_CODE",
  ERROR: "ERROR",
  DEACTIVATE_USER_MSG: "DEACTIVATE_USER_MSG",
  REACTIVATE_USER_MSG: "REACTIVATE_USER_MSG",
  LEAD_CREATED_DATE: "LEAD_CREATED_DATE",
  LEAD_STATUS: "LEAD_STATUS",
  LEADS_PENDING_ACTION: "LEADS_PENDING_ACTION",
  BTN_RESPONSE: "BTN_RESPONSE",
  LBL_CLOSED: "LBL_CLOSED",
  LBL_ACTIVE: "LBL_ACTIVE",
  RAISE_PROFILE_UPDATE_REQUEST: "RAISE_PROFILE_UPDATE_REQUEST",
  RAISE_PROFILE_UPDATE_REQUEST_MSG: "RAISE_PROFILE_UPDATE_REQUEST_MSG",
  LBL_YES: "LBL_YES",
  PLACEHOLDER_FROM: "PLACEHOLDER_FROM",
  PLACEHOLDER_TO: "PLACEHOLDER_TO",
  PLACEHOLDER_DEALER_ID: "PLACEHOLDER_DEALER_ID",
  BTN_CLEAR_FILTER: "BTN_CLEAR_FILTER",
  BTN_CLEAR: "BTN_CLEAR",
  USER_DEACTIVE: "USER_DEACTIVE",
  USER_ACTIVE: "USER_ACTIVE",
  LBL_NO: "LBL_NO",
  PROFILE_UPDATE_REQUEST_SUCCESS_MSG: "PROFILE_UPDATE_REQUEST_SUCCESS_MSG",
  FORM_VALID_MSG: "FORM_VALID_MSG",
  VALIDATION_MSG: "VALIDATION_MSG",
  VIEW_QUERIES: "VIEW_QUERIES",
  LIST_VEHICLE: "LIST_VEHICLE",
  VIEW_LESS: "VIEW_LESS",
  RECOMMENDATION_MSG: "RECOMMENDATION_MSG",
  FILTER_ALL: "FILTER_ALL",
  CURRENT_STATUS: "CURRENT_STATUS",
  BODY_STYLE: "BODY_STYLE",
  LOCATION: "LOCATION",
  ENGINE: "ENGINE",
  MAX_POWER: "MAX_POWER",
  DRIVE_TYPE: "DRIVE_TYPE",
  DOORS: "DOORS",
  SEATS: "SEATS",
  CYLINDERS: "CYLINDERS",
  LEAD_SHARE_DATE: "LEAD_SHARE_DATE",
  VEHICLE_INTERESTED: "VEHICLE_INTERESTED",
  SOURCE: "SOURCE",
  ASSIGN_TO: "ASSIGN_TO",
  NOTE: "NOTE",
  PREVIOUS_FOLLOWUP_SUMMARY: "PREVIOUS_FOLLOWUP_SUMMARY",
  CAR_NAME: "CAR_NAME",
  CAR_PRICE: "CAR_PRICE",
  ERR_MSG_TOP_BRANDS: "ERR_MSG_TOP_BRANDS",
  ERR_MSG_TOP_CITIES: "ERR_MSG_TOP_CITIES",
  ERR_MSG_EMPTY_FIELD: "ERR_MSG_EMPTY_FIELD",
  ERR_MSG_UP_TO_FOUR_DIGITS: "ERR_MSG_UP_TO_FOUR_DIGITS",
  ERR_MSG_UP_TO_THREE_DIGITS: "ERR_MSG_UP_TO_THREE_DIGITS",
  ERR_MSG_UP_TO_TWO_DIGITS: "ERR_MSG_UP_TO_TWO_DIGITS",
  ERR_MSG_UP_TO_SIX_DIGITS: "ERR_MSG_UP_TO_SIX_DIGITS",
  LESS_THAN: "LESS_THAN",
  YEARS: "YEARS",
  KM: "KM",
  DAYS: "DAYS",
  SELLER: "SELLER",
  OWNER: "OWNER",
  KILO_METER: "KILO_METER",
  FUEL: "FUEL",
  PAGE_NOT_FOUND: "PAGE_NOT_FOUND",
  RETURN_HOMEPAGE: "RETURN_HOMEPAGE",
  VEHICLE_NOT_FOUND: "VEHICLE_NOT_FOUND",
  DEALER_ID_CAPS: "DEALER_ID_CAPS",
  DEALER_NAME_CAPS: "DEALER_NAME_CAPS",
  PACKAGE_NAME: "PACKAGE_NAME",
  CONFIGURATION_NAME: "CONFIGURATION_NAME",
  DEALER: "DEALER",
  CURRENTLY_NO_DATA_AVAILABLE: "CURRENTLY_NO_DATA_AVAILABLE",
  NO_USER: "NO_USER",
  SPIN_CAR_360_VIEW: "SPIN_CAR_360_VIEW",
  PACKAGE_DESCRIPTION: "PACKAGE_DESCRIPTION",
  CREDIT_COUNT: "CREDIT_COUNT",
  CURRENT_PRICE: "CURRENT_PRICE",
  INACTIVE: "INACTIVE",
  SUMMARY_OF_QUERY: "SUMMARY_OF_QUERY",
  ADDED_BY: "ADDED_BY",
  ADD_NEW_CONFIGURATION: "ADD_NEW_CONFIGURATION",
  ADD_NEW_PRICING: "ADD_NEW_PRICING",
  PACKAGE_PRICING: "PACKAGE_PRICING",
  CURRENCY: "CURRENCY",
  PACKAGE_CONFIGURATION: "PACKAGE_CONFIGURATION",
  PACKAGE_DETAILS: "PACKAGE_DETAILS",
  DISPLAY_NAME: "DISPLAY_NAME",
  DESCRIPTION: "DESCRIPTION",
  ADD_PACKAGE: "ADD_PACKAGE",
  CONFIGURATION_VALID_THROUGH: "CONFIGURATION_VALID_THROUGH",
  DURATION: "DURATION",
  CONFIGURATION: "CONFIGURATION",
  PROMOTIONS: "PROMOTIONS",
  PACKAGES: "PACKAGES",
  DATE_ERROR_MSG: "DATE_ERROR_MSG",
  INTERIOR_COLOR_CODE: "INTERIOR_COLOR_CODE",
  EXTERIOR_COLOR_CODE: "EXTERIOR_COLOR_CODE",
  INTERIOR_COLOR_CODE_DESCRIPTION: "INTERIOR_COLOR_CODE_DESCRIPTION",
  EXTERIOR_COLOR_CODE_DESCRIPTION: "EXTERIOR_COLOR_CODE_DESCRIPTION",
  INTERIOR_COLOR_DESCRIPTION: "INTERIOR_COLOR_DESCRIPTION",
  EXTERIOR_COLOR_DESCRIPTION: "EXTERIOR_COLOR_DESCRIPTION",
  OWNERSHIP_DESCRIPTION: "OWNERSHIP_DESCRIPTION",
  TOTAL_CREDIT: "TOTAL_CREDIT",
  TOTAL_FEATURED_COUNT: "TOTAL_FEATURED_COUNT",
  LISTINGS: "LISTINGS",
  AVAILABLE_CREDIT: "AVAILABLE_CREDIT",
  AVAILABLE_FEATURE_COUNT: "AVAILABLE_FEATURE_COUNT",
  EXPIRING_ON: "EXPIRING_ON",
  ADD_NEW_CONFIG: "ADD_NEW_CONFIG",
  EDIT_CONFIG: "EDIT_CONFIG",
  NEGATIVE_PRICE_ERROR: "NEGATIVE_PRICE_ERROR",
  NEGATIVE_CREDIT_ERROR: "NEGATIVE_CREDIT_ERROR",
  NEGATIVE_FEATURE_ERROR: "NEGATIVE_FEATURE_ERROR",
  NEGATIVE_FEATURE_COUNT_ERROR: "NEGATIVE_FEATURE_COUNT_ERROR",
  ACTIVATE_MSG: "ACTIVATE_MSG",
  DEACTIVATE_MSG: "DEACTIVATE_MSG",
  CONFIRM_MSG: "CONFIRM_MSG",
  LBL_ACTIVATE: "LBL_ACTIVATE",
  DATA_SAVED_SUCCESSFULLY: "DATA_SAVED_SUCCESSFULLY",
  INVALID_DATA: "INVALID_DATA",
  INVOICE_NUMBER: "INVOICE_NUMBER",
  ENTER_PACKAGE_NAME: "ENTER_PACKAGE_NAME",
  ENTER_DISPLAY_NAME: "ENTER_DISPLAY_NAME",
  ENTER_DESCRIPTION: "ENTER_DESCRIPTION",
  ENTER_CREDIT_COUNT: "ENTER_CREDIT_COUNT",
  ENTER_FEATURE_DAYS: "ENTER_FEATURE_DAYS",
  ENTER_FEATURE_COUNT: "ENTER_FEATURE_COUNT",
  ENTER_CURRENCY: "ENTER_CURRENCY",
  ENTER_DURATION: "ENTER_DURATION",
  ENTER_CATEGORY: "ENTER_CATEGORY",
  ENTER_START_DATE: "ENTER_START_DATE",
  ENTER_END_DATE: "ENTER_END_DATE",
  ENTER_PRICE: "ENTER_PRICE",
  ERR_MSG_MAX_LENGTH: "ERR_MSG_MAX_LENGTH",
  PACKAGE_NAME_MAX_CHARACTER: "PACKAGE_NAME_MAX_CHARACTER",
  ADD_PACKAGE_NAME_MAX_CHARACTER: "ADD_PACKAGE_NAME_MAX_CHARACTER",
  DESCRIPTION_MAX_CHARACTER: "DESCRIPTION_MAX_CHARACTER",
  BUY_NOW: "BUY_NOW",
  DISPLAY_NAME_MAX_CHARACTER: "DISPLAY_NAME_MAX_CHARACTER",
  OFFER_TAB: "OFFER_TAB",
  CURRENT_PACKAGES_TAB: "CURRENT_PACKAGES_TAB",
  PACKAGE_HISTORY_TAB: "PACKAGE_HISTORY_TAB",
  AMOUNT: "AMOUNT",
  CHECKOUT: "CHECKOUT",
  SUBSCRIPTION: "SUBSCRIPTION",
  QUANTITY: "QUANTITY",
  LIST: "LIST",
  SELECT_SUBSCRIPTION: "SELECT_SUBSCRIPTION",
  DELETE: "DELETE",
  DELETION_CONFIRMATION: "DELETION_CONFIRMATION",
  REASON: "REASON",
  CONFIRM: "CONFIRM",
  ERR_MSG_MAX_150_LENGTH: "ERR_MSG_MAX_150_LENGTH",
  ERR_MSG_ZIP: "ERR_MSG_ZIP",
  NEXT: "NEXT",
  SEARCH: "SEARCH",
  IMAGE_SELECTION_FORMAT: "IMAGE_SELECTION_FORMAT",
  UPLOAD_DOCUMENT_SELLER: "UPLOAD_DOCUMENT_SELLER",
  SOLD_PRICE: "SOLD_PRICE",
  SOLD_TO: "SOLD_TO",
  MARK_AS_SOLD: "MARK_AS_SOLD",
  FILE_SIZE_EXCEEDS_10_MB: "FILE_SIZE_EXCEEDS_10_MB",
  SELECT_TO_ASSIGN_OR_LIST: "SELECT_TO_ASSIGN_OR_LIST",
  LISTING_WARNING_MSG: "LISTING_WARNING_MSG",
  DELIST_VEHICLE: "DELIST_VEHICLE",
  CONFIRM_DELIST_MSG: "CONFIRM_DELIST_MSG",
  DMS_FILTER_SAVED: "DMS_FILTER_SAVED",
  DMS_FILTER_INSPECTION_READY: "DMS_FILTER_INSPECTION_READY",
  DMS_FILTER_INSPECTION_REJECTED: "DMS_FILTER_INSPECTION_REJECTED",
  DMS_FILTER_QUALITY_CHECK_READY: "DMS_FILTER_QUALITY_CHECK_READY",
  DMS_FILTER_QUALITY_CHECK_REJECTED: "DMS_FILTER_QUALITY_CHECK_REJECTED",
  DMS_FILTER_LISTED: "DMS_FILTER_LISTED",
  DMS_FILTER_SOLD: "DMS_FILTER_SOLD",
  DMS_FILTER_AWAIT_LISTING: "DMS_FILTER_AWAIT_LISTING",
  DMS_FILTER_PENDING_INSPECTION: "DMS_FILTER_PENDING_INSPECTION",
  DMS_FILTER_NO_VEHICLE_ACTION: "DMS_FILTER_NO_VEHICLE_ACTION",
  DMS_FILTER_UNASSIGNED: "DMS_FILTER_UNASSIGNED",
  ONBOARDED_DEALERS: "ONBOARDED_DEALERS",
  ADDRESS_LINE_3: "ADDRESS_LINE_3",
  HEADER_NOTIFICATIONS: "HEADER_NOTIFICATIONS",
  HEADER_NO_NOTIFICATIONS: "HEADER_NO_NOTIFICATIONS",
  SEE_ALL: "SEE_ALL",
  HEADER_FEEDBACK: "HEADER_FEEDBACK",
  NUMBER: "NUMBER",
  BILL_TO_NAME: "BILL_TO_NAME",
  TOTAL_AMOUNT: "TOTAL_AMOUNT",
  CURRENT_AMOUNT: "CURRENT_AMOUNT",
  RELATED_INVOICE: "RELATED_INVOICE",
  TYPE: "TYPE",
  REFUND: "REFUND",
  ENTER_DEALER_NAME: "ENTER_DEALER_NAME",
  ENTER_STATUS: "ENTER_STATUS",
  ENTER_REFUND: "ENTER_REFUND",
  INVOICE: "INVOICE",
  INVOICES: "INVOICES",
  GREATER_THAN_HUNDRED: "GREATER_THAN_HUNDRED",
  HUNDRED_TO_TWO_HUNDRED: "HUNDRED_TO_TWO_HUNDRED",
  TWO_HUNDRED_TO_THREE_HUNDRED: "TWO_HUNDRED_TO_THREE_HUNDRED",
  THREE_HUNDRED_TO_FOUR_HUNDRED: "THREE_HUNDRED_TO_FOUR_HUNDRED",
  GREATER_THAN_FOUR_HUNDRED: "GREATER_THAN_FOUR_HUNDRED",
  INVOICE_STATUS: "INVOICE_STATUS",
  REFUNDED: "REFUNDED",
  REFUND_AMOUNT: "REFUND_AMOUNT",
  REFUND_REASON: "REFUND_REASON",
  PAYMENT_REFUND: "PAYMENT_REFUND",
  ENTER_REFUND_AMOUNT: "ENTER_REFUND_AMOUNT",
  ENTER_REFUND_REASON: "ENTER_REFUND_REASON",
  EXCEED_LIMIT_MESSAGE: "EXCEED_LIMIT_MESSAGE",
  VALID_NAME_FIELD: "VALID_NAME_FIELD",
  INVOICE_DETAILS: "INVOICE_DETAILS",
  INVOICE_ID: "INVOICE_ID",
  ADDRESS: "ADDRESS",
  REFUND_INVOICE: "REFUND_INVOICE",
  ORIGINAL_INVOICE: "ORIGINAL_INVOICE",
  LAST_CHANGE_ATTEMPT_DATE: "LAST_CHANGE_ATTEMPT_DATE",
  INVOICE_DATE: "INVOICE_DATE",
  INVOICE_ITEMS: "INVOICE_ITEMS",
  ITEM_ID: "ITEM_ID",
  SUBSCRIPTION_ID: "SUBSCRIPTION_ID",
  SALES_PRICE: "SALES_PRICE",
  VIEW_CHARGE_DETAILS: "VIEW_CHARGE_DETAILS",
  CHARGE_ID: "CHARGE_ID",
  CHARGE_PROCESS_DATE: "CHARGE_PROCESS_DATE",
  CHARGE_STATUS: "CHARGE_STATUS",
  MERCHANT_IDENTIFIER: "MERCHANT_IDENTIFIER",
  MERCHANT_REFERENCE: "MERCHANT_REFERENCE",
  TOKEN_NAME: "TOKEN_NAME",
  FORT_ID: "FORT_ID",
  CARD_NUMBER: "CARD_NUMBER",
  HOLDER_NAME: "HOLDER_NAME",
  CARD_EXPIRY: "CARD_EXPIRY",
  CARD_BIN: "CARD_BIN",
  STATUS_DES: "STATUS_DES",
  PAYMENT_OPTION: "PAYMENT_OPTION",
  CUSTOMER_EMAIL: "CUSTOMER_EMAIL",
  ACTION_ID: "ACTION_ID",
  OPERATION: "OPERATION",
  RESPONSE_CODE: "RESPONSE_CODE",
  RESPONSE_MESSAGE: "RESPONSE_MESSAGE",
  DATE_TIME: "DATE_TIME",
  CUSTOMER_IP: "CUSTOMER_IP",
  CHARGE_DETAILS: "CHARGE_DETAILS",
  NUMBER_ERR_MSG: "NUMBER_ERR_MSG",
  MAX_PRICE_VEHICLE_FILTER_MSG: "MAX_PRICE_VEHICLE_FILTER_MSG",
  MIN_PRICE_VEHICLE_FILTER_MSG: "MIN_PRICE_VEHICLE_FILTER_MSG",
  MAX_ASKING_PRICE_MSG: "MAX_ASKING_PRICE_MSG",
  MIN_ASKING_PRICE_MSG: "MIN_ASKING_PRICE_MSG",
  GENERAL_EMAIL: "GENERAL_EMAIL",
  INSPECTION_EMAIL: "INSPECTION_EMAIL",
  MAX_PRICE_CONFIGURE_MSG: "MAX_PRICE_CONFIGURE_MSG",
  MIN_PRICE_CONFIGURE_MSG: "MIN_PRICE_CONFIGURE_MSG",
  PRICING_CONFIGURATION: "PRICING_CONFIGURATION",
  MAX_PRICE_IN_VEHICLE_FILTER: "MAX_PRICE_IN_VEHICLE_FILTER",
  MIN_PRICE_IN_VEHICLE_FILTER: "MIN_PRICE_IN_VEHICLE_FILTER",
  MAX_ASKING_PRICE: "MAX_ASKING_PRICE",
  MIN_ASKING_PRICE: "MIN_ASKING_PRICE",
  EMAIL_CONFIGURATION: "EMAIL_CONFIGURATION",
  QUALITY_CHECK_EMAIL: "QUALITY_CHECK_EMAIL",
  AUTO_CARE_SUPPORT_EMAIL: "AUTO_CARE_SUPPORT_EMAIL",
  AUTO_CARE_BOOK_NOW_EMAIL: "AUTO_CARE_BOOK_NOW_EMAIL",
  BODY_AND_PAINT_BOOK_NOW_EMAIL: "BODY_AND_PAINT_BOOK_NOW_EMAIL",
  BODY_AND_PAINT_SUPPORT_EMAIL: "BODY_AND_PAINT_SUPPORT_EMAIL",
  CAREERS_EMAIL_ID: "CAREERS_EMAIL_ID",
  ACTIVATE_PACKAGE: "ACTIVATE_PACKAGE",
  DEACTIVATE_PACKAGE: "DEACTIVATE_PACKAGE",
  FEATURED_COUNT: "FEATURED_COUNT",
  ACTIVATE: "ACTIVATE",
  BULK_DEACTIVATE_USER_MSG: "BULK_DEACTIVATE_USER_MSG",
  DEACTIVATE_USERS: "DEACTIVATE_USERS",
  ERR_NO_NEGATIVE: "ERR_NO_NEGATIVE",
  VEHICLE_AGE_FILTER_0_TO_10_DAYS: "VEHICLE_AGE_FILTER_0_TO_10_DAYS",
  VEHICLE_AGE_FILTER_10_TO_20_DAYS: "VEHICLE_AGE_FILTER_10_TO_20_DAYS",
  VEHICLE_AGE_FILTER_20_TO_30_DAYS: "VEHICLE_AGE_FILTER_20_TO_30_DAYS",
  VEHICLE_AGE_FILTER_30_TO_40_DAYS: "VEHICLE_AGE_FILTER_30_TO_40_DAYS",
  VEHICLE_AGE_FILTER_40_TO_50_DAYS: "VEHICLE_AGE_FILTER_40_TO_50_DAYS",
  VEHICLE_AGE_FILTER_50_TO_60_DAYS: "VEHICLE_AGE_FILTER_50_TO_60_DAYS",
  VEHICLE_AGE_FILTER_GREATER_THAN_60_DAYS:
    "VEHICLE_AGE_FILTER_GREATER_THAN_60_DAYS",
  GRID_DATA_NOT_AVAILABLE: "GRID_DATA_NOT_AVAILABLE",
  GRID_PAGINATION: "GRID_PAGINATION",
  ACTIVE_PACKAGE_SUBSCRIPTION: "ACTIVE_PACKAGE_SUBSCRIPTION",
  PENDING_DMS_REQUEST: "PENDING_DMS_REQUEST",
  USERS: "USERS",
  AWAITED_LISTING: "AWAITED_LISTING",
  DELISTED: "DELISTED",
  INSPECTION_READY: "INSPECTION_READY",
  LISTED: "LISTED",
  QUALITY_CHECK_READY: "QUALITY_CHECK_READY",
  QUALITY_CHECK_REJECTED: "QUALITY_CHECK_REJECTED",
  SAVED: "SAVED",
  SOLD: "SOLD",
  NO_PACKAGE_AVAILABLE: "NO_PACKAGE_AVAILABLE",
  SERVICE: "PACKAGE",
  TOTAL_CREDIT_COUNT: "TOTAL_CREDIT_COUNT",
  EXPORT: "EXPORT",
  LISTING_STATUS: "LISTING_STATUS",
  QUALITY_ANALYSIS_STATUS_CODE: "QUALITY_ANALYSIS_STATUS_CODE",
  ASSIGNMENT_DATE: "ASSIGNMENT_DATE",
  TOTAL_LISTED_VEHICLE: "TOTAL_LISTED_VEHICLE",
  VIEW_DETAILS: "VIEW_DETAILS",
  TOTAL_INSPECTION_READY_VEHICLE: "TOTAL_INSPECTION_READY_VEHICLE",
  APPROVE: "APPROVE",
  REJECT: "REJECT",
  SELECT_TO_APPROVE_OR_REJECT: "SELECT_TO_APPROVE_OR_REJECT",
  PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
  PURCHASE_DETAILS: "PURCHASE_DETAILS",
  CONTINUE_LBL: "CONTINUE_LBL",
  PAYMENT_FAILURE: "PAYMENT_FAILURE",
  TRANSACTION_FAILED_MSG: "TRANSACTION_FAILED_MSG",
  REFUND_MONEY_MSG: "REFUND_MONEY_MSG",
  CHANGE_REASON: "CHANGE_REASON",
  PROFILE_IMAGE: "PROFILE_IMAGE",
  SHOW_ON_PORTAL: "SHOW_ON_PORTAL",
  ADD_PROMOTION: "ADD_PROMOTION",
  PROMOTION_NAME: "PROMOTION_NAME",
  CRITERIA: "CRITERIA",
  OFFER_CONTENT_ENGLISH: "OFFER_CONTENT_ENGLISH",
  OFFER_CONTENT_ARABIC: "OFFER_CONTENT_ARABIC",
  LBL_PROMOTIONS: "LBL_PROMOTIONS",
  ENTER_PROMOTION_NAME: "ENTER_PROMOTION_NAME",
  SELECT_CRITERIA: "SELECT_CRITERIA",
  ENTER_OFFER_CONTENT_ENGLISH: "ENTER_OFFER_CONTENT_ENGLISH",
  ENTER_OFFER_CONTENT_ARABIC: "ENTER_OFFER_CONTENT_ARABIC",
  DELETE_CONFIRM_MESSAGE: "DELETE_CONFIRM_MESSAGE",
  DELETE_PROMOTION: "DELETE_PROMOTION",
  EDIT_PROMOTION: "EDIT_PROMOTION",
  PROMOTION_ID: "PROMOTION_ID",
  CRITERIA_ID: "CRITERIA_ID",
  ASSIGNMENTS: "ASSIGNMENTS",
  GOGO_MOTOR: "GOGO_MOTOR",
  CONFIRM_REMOVE_FEATURE_MSG: "CONFIRM_REMOVE_FEATURE_MSG",
  REMOVE_FEATURE_VEHICLE: "REMOVE_FEATURE_VEHICLE",
  PAGINATION_MSG: "PAGINATION_MSG",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  ABOUT_US: "ABOUT_US",
  TERMS_CONDITIONS: "TERMS_CONDITIONS",
  ERROR_MSG_MIN_CHECK_IMAGES: "ERROR_MSG_MIN_CHECK_IMAGES",
  ERROR_MSG_MIN_CHECK: "ERROR_MSG_MIN_CHECK",
  LBL_PACKAGE_SUBSCRIPTION_VALIDATION: "LBL_PACKAGE_SUBSCRIPTION_VALIDATION",
  LBL_REMAINED_FEATURED_COUNT: "LBL_REMAINED_FEATURED_COUNT",
  CONFIRMATION_PROCEED_MSG: "CONFIRMATION_PROCEED_MSG",
  ENABLE_FEATURE_FUNCTIONALITY_MSG: "ENABLE_FEATURE_FUNCTIONALITY_MSG",
  PACKAGE_SUBSCRIPTION_EXPIRE_MSG: "PACKAGE_SUBSCRIPTION_EXPIRE_MSG",
  PACKAGE_SUBSCRIPTION_EXPIRED_MSG: "PACKAGE_SUBSCRIPTION_EXPIRED_MSG",
  CREDIT_COUNT_EXCEED: "CREDIT_COUNT_EXCEED",
  DEALER_CATEGORY: "DEALER_CATEGORY",
  SELECT_DEALER_CATEGORY: "SELECT_DEALER_CATEGORY",
  VEHICLE_ASSIGNED: "VEHICLE_ASSIGNED",
  ALERT: "ALERT",
  CONFIRMATION: "CONFIRMATION",
  MESSAGE: "MESSAGE",
  PHONE_NUMBER_ERROR_MESSAGE: "PHONE_NUMBER_ERROR_MESSAGE",
  ALTERNATE_PHONE_NUMBER_ERROR_MESSAGE: "ALTERNATE_PHONE_NUMBER_ERROR_MESSAGE",
  VEHICLE_APPROVED: "VEHICLE_APPROVED",
  VEHICLE_REJECTED: "VEHICLE_REJECTED",
  MULTIPLE_SPECIFICATIONS_FOUND: "MULTIPLE_SPECIFICATIONS_FOUND",
  VERIFY_BELOW_DETAIL: "VERIFY_BELOW_DETAIL",
  SAVED_VERIFIED_VEHICLE_SUCCESS: "SAVED_VERIFIED_VEHICLE_SUCCESS",
  VERIFY: "VERIFY",
  REFUND_AMOUNT_POSITIVE_VALUE_MSG: "REFUND_AMOUNT_POSITIVE_VALUE_MSG",
  VALID_ZIP_CODE: "VALID_ZIP_CODE",
  IMAGE_SELECTED_MESSAGE: "IMAGE_SELECTED_MESSAGE",
  NO_IMAGE_FOUND_MSG: "NO_IMAGE_FOUND_MSG",
  IMAGE_SELECTED_MESSAGE1: "IMAGE_SELECTED_MESSAGE1",
  IMAGE_SELECTED_MESSAGE2: "IMAGE_SELECTED_MESSAGE2",
  INTEGER_ERR_MSG: "INTEGER_ERR_MSG",
  PROFILE_CHANGE_MSG: "PROFILE_CHANGE_MSG",
  SPECIAL_CHARACTER_ERR: "SPECIAL_CHARACTER_ERR",
  INSPECTION_APPROVE_VALIDATION_MESSAGE:
    "INSPECTION_APPROVE_VALIDATION_MESSAGE",
  MULTIPLE_INSPECTION_APPROVE_VALIDATION_MESSAGE:
    "MULTIPLE_INSPECTION_APPROVE_VALIDATION_MESSAGE",
  REJECTION_REASON_ERROR: "REJECTION_REASON_ERROR",
  QC_APPROVED: "QC_APPROVED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  VEHICLE_LIST_COUNT_LBL: "VEHICLE_LIST_COUNT_LBL",
  ERR_ONLY_POSITIVE_NUMBER: "ERR_ONLY_POSITIVE_NUMBER",
  ERR_SAME_ANALYST_ASSIGN: "ERR_SAME_ANALYST_ASSIGN",
  ERR_SAME_SALESPERSON_ASSIGN: "ERR_SAME_SALESPERSON_ASSIGN",
  APPROVE_PROFILE_UPDATE_REQUEST_MSG: "APPROVE_PROFILE_UPDATE_REQUEST_MSG",
  REJECT_PROFILE_UPDATE_REQUEST_MSG: "REJECT_PROFILE_UPDATE_REQUEST_MSG",
  VAT_AMOUNT: "VAT_AMOUNT",
  VAT_PERCENTAGE: "VAT_PERCENTAGE",
  PROFILE_UPDATE_SUCCESS_MSG: "PROFILE_UPDATE_SUCCESS_MSG",
  MAX_PRICE_PACKAGE_CONFIGURATION_MSG: "MAX_PRICE_PACKAGE_CONFIGURATION_MSG",
  DOCUMENT_PROCESS_MSG: "DOCUMENT_PROCESS_MSG",
  INSPECTION_REJECT_CONFIRMATION: "INSPECTION_REJECT_CONFIRMATION",
  DISPLAY_AMOUNT: "DISPLAY_AMOUNT",
  ENTER_DISPLAY_AMOUNT: "DISPLAY_AMOUNT",
  TOTAL_FEEDBACK: "TOTAL_FEEDBACK",
  RATING_TREND: "RATING_TREND",
  FEEDBACK_RATING: "FEEDBACK_RATING",
  SELLER_BUYER_IMMEDIATE: "SELLER_BUYER_IMMEDIATE",
  SELLER_BUYER_POSTSELL: "SELLER_BUYER_POSTSELL",
  TOTAL_COUNT: "TOTAL_COUNT",
  RATING: "RATING",
  FLTR_OTHER: "FLTR_OTHER",
  REFUND_DATE: "REFUND_DATE",
  INSPECTION_TREND: "INSPECTION_TREND",
  CREDIT_NOTES: "CREDIT_NOTES",
  TAX_PERCENTAGE: "TAX_PERCENTAGE",
  TOTAL_TAX_PAID: "TOTAL_TAX_PAID",
  CREDIT_NOTE_ID: "CREDIT_NOTE_ID",
  VEHICLE_AGE_INTEGER_MSG: "VEHICLE_AGE_INTEGER_MSG",
  ODOMETER_READING_INTEGER_MSG: "ODOMETER_READING_INTEGER_MSG",
  FEEDBACK_TYPE: "FEEDBACK_TYPE",
  FEEDBACK_CREATED: "FEEDBACK_CREATED",
  FEEDBACK_NOT_FOUND: "FEEDBACK_NOT_FOUND",
  FEEDBACK: "FEEDBACK",
  OVERALL_RATING: "OVERALL_RATING",
  FEATURE_START_DATE: "FEATURE_START_DATE",
  FEATURE_END_DATE: "FEATURE_END_DATE",
  ALL: "ALL",
  FEEDBACK_VIDEO: "FEEDBACK_VIDEO",
  FEEDBACK_IMAGE: "FEEDBACK_IMAGE",
  MARK_AS_SOLD_MSG: "MARK_AS_SOLD_MSG",
  DMS_FILTER_DELISTED: "DMS_FILTER_DELISTED",
  OVERVIEW: "OVERVIEW",
  KEEP_IN_TOUCH: "KEEP_IN_TOUCH",
  MORE_ABOUT_WEBSITE: "MORE_ABOUT_WEBSITE",
  COPYRIGHT_GOGOMOTOR: "COPYRIGHT_GOGOMOTOR",
  CAREERS: "CAREERS",
  CONTACT_US: "CONTACT_US",
  BTN_FEATURED: "BTN_FEATURED",
  NO_CART_ITEM_MSG: "NO_CART_ITEM_MSG",
  CLICK_HERE: "CLICK_HERE",
  VEHICLE_HISTORY: "VEHICLE_HISTORY",
  THANK_YOU_SPENDING_VALUABLE_TIME: "THANK_YOU_SPENDING_VALUABLE_TIME",
  YOUR_INFORMATION: "YOUR_INFORMATION",
  ENTER_COMMENTS: "ENTER_COMMENTS",
  ENTER_NAME: "ENTER_NAME",
  ENTER_EMAIL: "ENTER_EMAIL",
  PAGE_SIZE: "PAGE_SIZE",
  SEQUENCE_NUMBER: "SEQUENCE_NUMBER",
  SEQUENCE_NUMBER_DESCRIPTION: "SEQUENCE_NUMBER_DESCRIPTION",
  PLATE_TEXT_1: "PLATE_TEXT_1",
  PLATE_TEXT_1_DESCRIPTION: "PLATE_TEXT_1_DESCRIPTION",
  PLATE_TEXT_2: "PLATE_TEXT_2",
  PLATE_TEXT_2_DESCRIPTION: "PLATE_TEXT_2_DESCRIPTION",
  PLATE_TEXT_3: "PLATE_TEXT_3",
  PLATE_TEXT_3_DESCRIPTION: "PLATE_TEXT_3_DESCRIPTION",
  PLATE_NUMBER: "PLATE_NUMBER",
  PLATE_NUMBER_DESCRIPTION: "PLATE_NUMBER_DESCRIPTION",
  OWNER_ID: "OWNER_ID",
  OWNER_ID_DESCRIPTION: "OWNER_ID_DESCRIPTION",
  CR_NUMBER_MATCHED: "CR_NUMBER_MATCHED",
  CR_NUMBER_NOT_MATCH: "CR_NUMBER_NOT_MATCH",
  BUILDING_NUMBER: "BUILDING_NUMBER",
  ADDRESS_NUMBER: "ADDRESS_NUMBER",
  POST_CODE: "POST_CODE",
  STREET_NUMBER: "STREET_NUMBER",
  UNIT_NUMBER: "UNIT_NUMBER",
  COMPANY_ADDRESS: "COMPANY_ADDRESS",
  DISTRICT: "DISTRICT",
  VEHICLES_APPROVED_PER_MONTH: "VEHICLES_APPROVED_PER_MONTH",
  VEHICLES_REJECTED_PER_MONTH: "VEHICLES_REJECTED_PER_MONTH",
  PORTAL_USER: "PORTAL_USER",
  GUEST_USER: "GUEST_USER",
  DMS_USER: "DMS_USER",
  SHOULD_NOT_START_WITH_ZERO: "SHOULD_NOT_START_WITH_ZERO",
  NUMBER_NOT_SAME_AS_PRIMARY: "NUMBER_NOT_SAME_AS_PRIMARY",
  ABOUT_GOGO_MOTOR: "ABOUT_GOGO_MOTOR",
  EXPIRING_ON_LATEST: "EXPIRING_ON_LATEST",
  UPDATED_DATE: "UPDATED_DATE",
  PRICE_CONFIGURATION: "PRICE_CONFIGURATION",
  ALL_NOTIFICATIONS: "ALL_NOTIFICATIONS",
  FIRST: "FIRST",
  PAGINATION_BACK: "PAGINATION_BACK",
  LAST: "LAST",
  HIGH_TO_LOW: "HIGH_TO_LOW",
  LOW_TO_HIGH: "LOW_TO_HIGH",
  SHOW: "SHOW",
  ENGLISH: "ENGLISH",
  ARABIC: "ARABIC",
  PLEASE_SELECT_PREFERRED_LANGUAGE: "PLEASE_SELECT_PREFERRED_LANGUAGE",
  PREFERRED_LANGUAGE: "PREFERRED_LANGUAGE",
  FORGOT_PASSWORD_SUCCESS_MESSAGE: "FORGOT_PASSWORD_SUCCESS_MESSAGE",
  VALUE: "VALUE",
  REFRESHES: "REFRESHES",
  PREMIUM: "PREMIUM",
  ENTER_REFRESHES: "ENTER_REFRESHES",
  ENTER_PREMIUM: "ENTER_PREMIUM",
  NEGATIVE_REFRESHES_ERROR: "NEGATIVE_REFRESHES_ERROR",
  NEGATIVE_PREMIUM_ERROR: "NEGATIVE_PREMIUM_ERROR",
  REFRESH_COUNT: "REFRESH_COUNT",
  PREMIUM_COUNT: "PREMIUM_COUNT",
  PACKAGE_ASSIGNED_SUCCESSFULLY: "PACKAGE_ASSIGNED_SUCCESSFULLY",
  PACKAGE_ASSIGNED_FAILED: "PACKAGE_ASSIGNED_FAILED",
  INVOICE_GENERATED_MESSAGE: "INVOICE_GENERATED_MESSAGE",
  PACKAGE_ACTIVATE_MESSAGE: "PACKAGE_ACTIVATE_MESSAGE",
  PACKAGE_FAILED_MESSAGE: "PACKAGE_FAILED_MESSAGE",
  AVAILABLE_REFRESH_COUNT: "AVAILABLE_REFRESH_COUNT",
  TOTAL_REFRESH_COUNT: "TOTAL_REFRESH_COUNT",
  AVAILABLE_PREMIUM_COUNT: "AVAILABLE_PREMIUM_COUNT",
  TOTAL_PREMIUM_COUNT: "TOTAL_PREMIUM_COUNT",
  ADDITIONAL_FEATURES: "ADDITIONAL_FEATURES",
  DIAMOND: "DIAMOND",
  PLATINUM: "PLATINUM",
  GOLD: "GOLD",
  SILVER: "SILVER",
  LOGO: "LOGO",
  LEAD_TRACKING: "LEAD_TRACKING",
  INSPECTION_REPORTS: "INSPECTION_REPORTS",
  INSPECTION_EQUIPMENT: "INSPECTION_EQUIPMENT",
  CONTENT_CAPTURE: "CONTENT_CAPTURE",
  ADMIN_TRAINING: "ADMIN_TRAINING",
  ON_SITE: "ON_SITE",
  IN_HOUSE: "IN_HOUSE",
  SALES_TRAINING: "SALES_TRAINING",
  CRM: "CRM",
  USERS_20: "USERS_20",
  USERS_10: "USERS_10",
  USERS_6: "USERS_6",
  USERS_1: "USERS_1",
  DISCLAIMERS: "DISCLAIMERS",
  DISCLAIMER_1: "DISCLAIMER_1",
  DISCLAIMER_2: "DISCLAIMER_2",
  DISCLAIMER_3: "DISCLAIMER_3",
  DISCLAIMER_4: "DISCLAIMER_4",
  SLAB: "SLAB",
  FEATURED: "FEATURED",
  PRICE_SAR: "PRICE_SAR",
  PACKAGE_NAME_AR: "PACKAGE_NAME_AR",
  DESCRIPTION_AR: "DESCRIPTION_AR",
  PACKAGE_DESCRIPTION_AR: "PACKAGE_DESCRIPTION_AR",
  REGION: "REGION",
  SELECT_REGION: "SELECT_REGION",
  ERR_MSG_REGION: "ERR_MSG_REGION",
  PAYMENT_LINK_BTN: "PAYMENT_LINK_BTN",
  PAYMENT_LINK_COPY_MSG: "PAYMENT_LINK_COPY_MSG",
  VEHICLE_CONDITION: "VEHICLE_CONDITION",
  LICENSE_PLATE_NUMBER: "LICENSE_PLATE_NUMBER",
  DATA_ADDED_SUCCESS: "DATA_ADDED_SUCCESS",
  DATA_UPDATE_SUCCESS: "DATA_UPDATE_SUCCESS",
  SELECT_JPEG_OR_PNG_IMAGE: "SELECT_JPEG_OR_PNG_IMAGE",
  CSV: "CSV",
  XLSX: "XLSX",
  REFRESH: "REFRESH",
  EXPORT_ERROR_RECORDS: "EXPORT_ERROR_RECORDS",
  ERROR_RECORDS: "ERROR_RECORDS",
  ERROR_DESCRIPTION: "ERROR_DESCRIPTION",
  ALL_CITIES: "ALL_CITIES",
  VIEW_ALL_CITIES: "VIEW_ALL_CITIES",
  VIEW_EXTERIOR_COLOR: "VIEW_EXTERIOR_COLOR",
  VIEW_INTERIOR_COLOR: "VIEW_INTERIOR_COLOR",
  VIEW_OWNERSHIP: "VIEW_OWNERSHIP",
  VIEW_INSPECTION_LOCATION: "VIEW_INSPECTION_LOCATION",
  FILE_FORMAT: "FILE_FORMAT",
  BTN_CHECK_PROGRESS: "BTN_CHECK_PROGRESS",
  MANDATORY_NOTE: "MANDATORY_NOTE",
  VEHICLE_DETAIL: "VEHICLE_DETAIL",
  SEARCH_BY_ID: "SEARCH_BY_ID",
  DOCUMENTS: "DOCUMENTS",
  CUSTOMER_IMAGE: "CUSTOMER_IMAGE",
  DETAILS: "DETAILS",
  INSPECTION_IMAGE: "INSPECTION_IMAGE",
  VEHICLE_HISTORY_ERROR_MESSAGE: "VEHICLE_HISTORY_ERROR_MESSAGE",
  VEHICLE_HISTORY_REQUIRED_MESSAGE: "VEHICLE_HISTORY_REQUIRED_MESSAGE",
  VEHICLE_HISTORY_CONFIRMATION: "VEHICLE_HISTORY_CONFIRMATION",
  VIEW_HISTORY: "VIEW_HISTORY",
  REFRESH_HISTORY: "REFRESH_HISTORY",
  GET_HISTORY: "GET_HISTORY",
  SPIN_IMAGE_TITLE: "SPIN_IMAGE_TITLE",
  NO_DATA_AVAILABLE: "NO_DATA_AVAILABLE",
  INSPECTION_IMAGE_TITLE: "INSPECTION_IMAGE_TITLE",
  CUSTOMER_IMAGE_TITLE: "CUSTOMER_IMAGE_TITLE",
  IMPEL_IMAGE: "IMPEL_IMAGE",
  SPIN_CAR_IMAGE_NOTE: "SPIN_CAR_IMAGE_NOTE",
  NEW: "NEW",
  CAR_TYPE: "CAR_TYPE",
  QUOTATIONS: "QUOTATIONS",
  QUOTATION_NUMBER: "QUOTATION_NUMBER",
  QUOTATION_DATE: "QUOTATION_DATE",
  SELLER_FIRST_NAME: "SELLER_FIRST_NAME",
  SELLER_LAST_NAME: "SELLER_LAST_NAME",
  SELLER_PHONE_NUMBER: "SELLER_PHONE_NUMBER",
  BUYER_ID: "BUYER_ID",
  BUYER_FIRST_NAME: "BUYER_FIRST_NAME",
  BUYER_LAST_NAME: "BUYER_LAST_NAME",
  BUYER_EMAIL: "BUYER_EMAIL",
  BUYER_PHONE_NUMBER: "BUYER_PHONE_NUMBER",
  ENTER_NUMBERS_ONLY: "ENTER_NUMBERS_ONLY",
  ADD_COMMENT: "ADD_COMMENT",
  UPDATE_COMMENT: "UPDATE_COMMENT",
  UPDATE_COMMENT_SUCCESS: "UPDATE_COMMENT_SUCCESS",
  QUALITY_CHECK_CONFIRMATION_MSG: "QUALITY_CHECK_CONFIRMATION_MSG",
  SAVE_FOR_QUALITY_CHECK: "SAVE_FOR_QUALITY_CHECK",
  B2C_PACKAGES: "B2C_PACKAGES",
  IS_RECOMMENDED: "IS_RECOMMENDED",
  SERVICE_DETAILS: "SERVICE_DETAILS",
  SERVICE_NAME: "SERVICE_NAME",
  SERVICE_NAME_AR: "SERVICE_NAME_AR",
  ENTER_SERVICE_NAME: "ENTER_SERVICE_NAME",
  VALIDITY: "VALIDITY",
  ADD_SERVICE: "ADD_SERVICE",
  ACTIVATE_PACKAGE_CONFIG: "ACTIVATE_PACKAGE_CONFIG",
  DEACTIVATE_PACKAGE_CONFIG: "DEACTIVATE_PACKAGE_CONFIG",
  ACTIVATE_MSG_CONFIG: "ACTIVATE_MSG_CONFIG",
  DEACTIVATE_MSG_CONFIG: "DEACTIVATE_MSG_CONFIG",
  COUNTRY: "COUNTRY",
  SELECT_COUNTRY: "SELECT_COUNTRY",
  OFFER: "OFFER",
  IS_STANDALONE: "IS_STANDALONE",
  IS_PACKAGED: "IS_PACKAGED",
  AVAILABILITY: "AVAILABILITY",
  IS_B2C_APPLICABLE: "IS_B2C_APPLICABLE",
  IS_B2B_APPLICABLE: "IS_B2B_APPLICABLE",
  IS_CRM_APPLICABLE: "IS_CRM_APPLICABLE",
  SERVICE_CREATED_SUCCESS: "SERVICE_CREATED_SUCCESS",
  ADD_NEW_SERVICE_CONFIGURATION: "ADD_NEW_SERVICE_CONFIGURATION",
  EDIT_SERVICE_CONFIG: "EDIT_SERVICE_CONFIG",
  ACTIVATE_SERVICE: "ACTIVATE_SERVICE",
  DEACTIVATE_SERVICE: "DEACTIVATE_SERVICE",
  STANDLONE_PRICE: "STANDLONE_PRICE",
  STANDLONE_DISPLAY_PRICE: "STANDLONE_DISPLAY_PRICE",
  SERVICE_ACTIVATE_MSG_CONFIG: "SERVICE_ACTIVATE_MSG_CONFIG",
  SERVICE_DEACTIVATE_MSG_CONFIG: "SERVICE_DEACTIVATE_MSG_CONFIG",
  SERVICE_ACTIVATE_MSG: "SERVICE_ACTIVATE_MSG",
  SERVICE_DEACTIVATE_MSG: "SERVICE_DEACTIVATE_MSG",
  ADD_NEW_PACKAGE: "ADD_NEW_PACKAGE",
  SELECT_LANGUAGE: "SELECT_LANGUAGE",
  ASSIGN_NEW_SERVICE: "ASSIGN_NEW_SERVICE",
  SELECT_SERVICE: "SELECT_SERVICE",
  SELECTED_SERVICES: "SELECTED_SERVICES",
  REMOVE: "REMOVE",
  CHANGE_MSG: "CHANGE_MSG",
  PLEASE_SELECT_LANGUAGE: "PLEASE_SELECT_LANGUAGE",
  ADD_NEW_BTN: "ADD_NEW_BTN",
  PLEASE_ENTER_NAME: "PLEASE_ENTER_NAME",
  ADD_NAME_MAX_CHARACTER: "ADD_NAME_MAX_CHARACTER",
  SERVICE_PARAMETER: "SERVICE_PARAMETER",
  SERVICE_VALUE: "SERVICE_VALUE",
  PACKAGE_UPDATED_SUCCESS: "PACKAGE_UPDATED_SUCCESS",
  PACKAGE_CREATION_SUCCESS: "PACKAGE_CREATION_SUCCESS",
  SERVICE_UPDATED_SUCCESS: "SERVICE_UPDATED_SUCCESS",
  CONFIGURATION_DESCRIPTION: "CONFIGURATION_DESCRIPTION",
  DO_YOU_WANT_TO_SHOW_ON_PORTAL: "DO_YOU_WANT_TO_SHOW_ON_PORTAL",
  SEQUENCE_CHANGED_SUCCESSFULLY: "SEQUENCE_CHANGED_SUCCESSFULLY",
  REARRANGE_LIST: "REARRANGE_LIST",
  IS_VEHICLE_LINKED: "IS_VEHICLE_LINKED",
  NO_SERVICE_ALLOCATED: "NO_SERVICE_ALLOCATED",
  ADD_SERVICE_PARAMETER_BTN: "ADD_SERVICE_PARAMETER_BTN",
  CUSTOMER: "CUSTOMER",
  B2B_PACKAGES: "B2B_PACKAGES",
  TOTAL_ACTIVE_B2B_SUBSCRIPTION: "TOTAL_ACTIVE_B2B_SUBSCRIPTION",
  TOTAL_ACTIVE_B2C_SUBSCRIPTION: "TOTAL_ACTIVE_B2C_SUBSCRIPTION",
  PROMOTION_CODE: "PROMOTION_CODE",
  PROMOTION_VALUE: "PROMOTION_VALUE",
  MAX_COUNT: "MAX_COUNT",
  CURRENT_COUNT: "CURRENT_COUNT",
  PROMOTION_TYPE: "PROMOTION_TYPE",
  PROMOTION: "PROMOTION",
  ADD_PROMOTION_CODE: "ADD_PROMOTION_CODE",
  EDIT_PROMOTION_CODE: "EDIT_PROMOTION_CODE",
  ENTER_PROMOTION_NAME_ENG: "ENTER_PROMOTION_NAME_ENG",
  ENTER_ERROR_ENG: "ENTER_ERROR_ENG",
  ENTER_MAX_ERROR_ENG: "ENTER_MAX_ERROR_ENG",
  ENTER_PROMOTION_CODE: "ENTER_PROMOTION_CODE",
  ENTER_PROMOTION_VALUE: "ENTER_PROMOTION_VALUE",
  ENTER_MAX_COUNT: "ENTER_MAX_COUNT",
  ENTER_PROMOTION_TYPE: "ENTER_PROMOTION_TYPE",
  PROMOTION_ACTIVATE_MSG: "PROMOTION_ACTIVATE_MSG",
  PROMOTION_DEACTIVATE_MSG: "PROMOTION_DEACTIVATE_MSG",
  LBL_PROMOTION_NAME_ENG: "LBL_PROMOTION_NAME_ENG",
  LBL_PROMOTION_NAME_ARB: "LBL_PROMOTION_NAME_ARB",
  LBL_PROMOTION_DESCRIPTION_ENG: "LBL_PROMOTION_DESCRIPTION_ENG",
  LBL_PROMOTION_DESCRIPTION_ARB: "LBL_PROMOTION_DESCRIPTION_ARB",
  LBL_PROMOTION_CODE: "LBL_PROMOTION_CODE",
  LBL_PROMOTION_TYPE: "LBL_PROMOTION_TYPE",
  LBL_PROMOTION_ERROR_MSG_ENG: "LBL_PROMOTION_ERROR_MSG_ENG",
  LBL_PROMOTION_ERROR_MSG_ARB: "LBL_PROMOTION_ERROR_MSG_ARB",
  LBL_PROMOTION_MAX_COUNT_ERROR_MSG_ENG:
    "LBL_PROMOTION_MAX_COUNT_ERROR_MSG_ENG",
  LBL_PROMOTION_MAX_COUNT_ERROR_MSG_ARB:
    "LBL_PROMOTION_MAX_COUNT_ERROR_MSG_ARB",
  LBL_PROMOTION_VALUE: "LBL_PROMOTION_VALUE",
  LBL_PROMOTION_MAX_COUNT: "LBL_PROMOTION_MAX_COUNT",
  LBL_PROMOTION_CURRENT_COUNT: "LBL_PROMOTION_CURRENT_COUNT",
  HDR_SET_VERIFICATION_CRITERIA: "HDR_SET_VERIFICATION_CRITERIA",
  ENTER_PROMOTION_MAX_COUNT: "ENTER_PROMOTION_MAX_COUNT",
  PUBLISH_AND_ACTIVATE: "PUBLISH_AND_ACTIVATE",
  SAVE_ASSIGN: "SAVE_ASSIGN",
  ADD_PACKAGE_DETAILS: "ADD_PACKAGE_DETAILS",
  PICK_COLOR: "PICK_COLOR",
  ASSOCIATE_SERVICES: "ASSOCIATE_SERVICES",
  PUBLISH_PACKAGE: "PUBLISH_PACKAGE",
  SHOW_SUMMARY: "SHOW_SUMMARY",
  SET_DISPLAY_TEXT: "SET_DISPLAY_TEXT",
  SELECT_ATTRIBUTE: "SELECT_ATTRIBUTE",
  ATTRIBUTE: "ATTRIBUTE",
  DISPLAY_PRICE: "DISPLAY_PRICE",
  ADD_NEW_SERVICE: "ADD_NEW_SERVICE",
  UPDATE_SERVICE: "UPDATE_SERVICE",
  CODE: "CODE",
  UPDATE_PACKAGE_DETAILS: "UPDATE_PACKAGE_DETAILS",
  SERVICE_DELETE_MSG: "SERVICE_DELETE_MSG",
  VALID_COLOR_CODE: "VALID_COLOR_CODE",
  ASSOCIATED_SUCCESS: "ASSOCIATED_SUCCESS",
  HDR_CUSTOMIZE: "HDR_CUSTOMIZE",
  PROMOTION_USAGE: "PROMOTION_USAGE",
  FAILURE_REASON: "FAILURE_REASON",
  IS_SUCCESSFUL: "IS_SUCCESSFUL",
  APPLIED_DATE: "APPLIED_DATE",
  PROMO_CODE: "PROMO_CODE",
  ALL_PROMOTIONS: "ALL_PROMOTIONS",
  ENTER_COLOR_CODE: "ENTER_COLOR_CODE",
  DISPLAY_TEXT: "DISPLAY_TEXT",
  ERR_MSG_PACKAGE_SERVICE_ASSOCIATE: "ERR_MSG_PACKAGE_SERVICE_ASSOCIATE",
  SEARCH_TEXT: "SEARCH_TEXT",
  COMMENT_PLACEHOLDER: "COMMENT_PLACEHOLDER",
  UPDATE_ASSIGNMENT: "UPDATE_ASSIGNMENT",
  BANNER_IMAGE: "BANNER_IMAGE",
  BANNER_IMAGE_UPLOAD: "BANNER_IMAGE_UPLOAD",
  VIEW_BANNER: "VIEW_BANNER",
  DEFAULT_BANNER_WEB: "DEFAULT_BANNER_WEB",
  DEFAULT_BANNER_MOBILE: "DEFAULT_BANNER_MOBILE",
  BANNER_WEB: "BANNER_WEB",
  BANNER_MOBILE: "BANNER_MOBILE",
  CMS: "CMS",
  ARTIFACT_WIDTH: "ARTIFACT_WIDTH",
  ARTIFACT_HEIGHT: "ARTIFACT_HEIGHT",
  INVALID_IMG_DIMENSION: "INVALID_IMG_DIMENSION",
  ERR_MSG_EMPTY_IMG: "ERR_MSG_EMPTY_IMG",
  IMAGE_SIZE_MSG: "IMAGE_SIZE_MSG",
  IMAGE_FORMAT_MSG: "IMAGE_FORMAT_MSG",
  IMAGE_DIMENSION_MSG: "IMAGE_DIMENSION_MSG",
  QUOTATION_STATUS: "QUOTATION_STATUS",
  SELECT_QUOTATION_STATUS: "SELECT_QUOTATION_STATUS",
  TOTAL_DMS_USER: "TOTAL_DMS_USER",
  TOTAL_CUSTOMER_USER: "TOTAL_CUSTOMER_USER",
  SELECT_JPEG_OR_PNG_OR_SVG_OR_ICO_IMAGE:
    "SELECT_JPEG_OR_PNG_OR_SVG_OR_ICO_IMAGE",
  LBL_UPLOAD_ICON: "LBL_UPLOAD_ICON",
  GET_DETAILS: "GET_DETAILS",
  VIN_NUMBER: "VIN_NUMBER",
  INVALID_VIN: "INVALID_VIN",
  VEHICLE_DATA_NOT_AVAILABLE: "VEHICLE_DATA_NOT_AVAILABLE",
  REQUIRED_FIELD: "REQUIRED_FIELD",
  UPLOAD_ATLEAST_ONE_IMAGE: "UPLOAD_ATLEAST_ONE_IMAGE",
  UPLOAD_ATLEAST_ONE_MANUFACTURER_IMAGE:
    "UPLOAD_ATLEAST_ONE_MANUFACTURER_IMAGE",
  UPLOAD_PKG_ICON_NOTE: "UPLOAD_PKG_ICON_NOTE",
  DROPDOWN_REASON_LBL: "DROPDOWN_REASON_LBL",
  CHANGED_DATE: "CHANGED_DATE",
  CREATED_BY: "CREATED_BY",
  ERR_MSG_MAX_1000_LENGTH: "ERR_MSG_MAX_1000_LENGTH",
  ACCOUNT_NOT_FOUND: "ACCOUNT_NOT_FOUND",
  DOCUMENT_ID: "DOCUMENT_ID",
  FILE_NAME: "FILE_NAME",
  UPLOADED_DATE: "UPLOADED_DATE",
  UPLOADED_BY: "UPLOADED_BY",
  QUOTATION: "QUOTATION",
  QUOTATION_HISTORY: "QUOTATION_HISTORY",
  ADMIN_CONFIG: "ADMIN_CONFIG",
  ADD_QUOTATION_STATUS: "ADD_QUOTATION_STATUS",
  STATUS_NAME: "STATUS_NAME",
  DELIVERY_NOTE: "DELIVERY_NOTE",
  VEHICLE_PROFILE_SAVED: "VEHICLE_PROFILE_SAVED",
  ADD_MANUFACTURER_IMAGES: "ADD_MANUFACTURER_IMAGES",
  ADD_IMAGES: "ADD_IMAGES",
  IMAGES: "IMAGES",
  ADD_QC_REJECTION_STATUS: "ADD_QC_REJECTION_STATUS",
  QC_REJECTION_STATUS: "QC_REJECTION_STATUS",
  QUOTATION_STATUS_CREATED_SUCCESSFULLY:
    "QUOTATION_STATUS_CREATED_SUCCESSFULLY",
  QUOTATION_STATUS_UPDATED_SUCCESSFULLY:
    "QUOTATION_STATUS_UPDATED_SUCCESSFULLY",
  QC_REJECTION_STATUS_CREATED_SUCCESSFULLY:
    "QC_REJECTION_STATUS_CREATED_SUCCESSFULLY",
  QC_REJECTION_STATUS_UPDATED_SUCCESSFULLY:
    "QC_REJECTION_STATUS_UPDATED_SUCCESSFULLY",
  QUALITY_CHECK_REJECTION_REASON_ALREADY_EXISTS:
    "QUALITY_CHECK_REJECTION_REASON_ALREADY_EXISTS",
  UPDATE_QC_REJECTION_STATUS: "UPDATE_QC_REJECTION_STATUS",
  UPDATE_QUOTATION_STATUS: "UPDATE_QUOTATION_STATUS",
  QUOTATION_REASON_ALREADY_EXISTS: "QUOTATION_REASON_ALREADY_EXISTS",
  QUOTATION_ACTIVATE_MSG: "QUOTATION_ACTIVATE_MSG",
  QUOTATION_DEACTIVATE_MSG: "QUOTATION_DEACTIVATE_MSG",
  QUOTATION_STATUS_ACTIVATED: "QUOTATION_STATUS_ACTIVATED",
  QUOTATION_STATUS_DEACTIVATED: "QUOTATION_STATUS_DEACTIVATED",
  QC_REJECTION_STATUS_ACTIVATED: "QC_REJECTION_STATUS_ACTIVATED",
  QC_REJECTION_STATUS_DEACTIVATED: "QC_REJECTION_STATUS_DEACTIVATED",
  QC_REJECTION_ACTIVATE_MSG: "QC_REJECTION_ACTIVATE_MSG",
  QC_REJECTION_DEACTIVATE_MSG: "QC_REJECTION_DEACTIVATE_MSG",
  BTN_DOWNLOAD_XML: "BTN_DOWNLOAD_XML",
  LBL_RESIDUAL_VALUE: "LBL_RESIDUAL_VALUE",
  MIN_PRICE_ZERO_CONFIGURE_MSG: "MIN_PRICE_ZERO_CONFIGURE_MSG",
  LBL_APPROVE_SUBMIT: "LBL_APPROVE_SUBMIT",
  ADDITIONAL_NUMBER: "ADDITIONAL_NUMBER",
  PROVINCE: "PROVINCE",
  SELECT_PROVINCE: "SELECT_PROVINCE",
  ERR_MSG_MAX_SERVICE_LENGTH_200: "ERR_MSG_MAX_SERVICE_LENGTH_200",
  LBL_QC_REJECTION_REASONS: "LBL_QC_REJECTION_REASONS",
  BOOLEAN_ERR_MSG: "BOOLEAN_ERR_MSG",
  STRING_ERR_MSG: "STRING_ERR_MSG",
  INT_ERR_MSG: "INT_ERR_MSG",
  DEFAULT_EMI_CONFIG: "DEFAULT_EMI_CONFIG",
  AMORTIZATION_PERIOD: "AMORTIZATION_PERIOD",
  BALLOON_PAYMENT_PERCENTAGE: "BALLOON_PAYMENT_PERCENTAGE",
  BALLOON_PERIOD: "BALLOON_PERIOD",
  INTEREST_RATE_LBL: "INTEREST_RATE_LBL",
  MONTHLY_EMI_MSG: "MONTHLY_EMI_MSG",
  PLEASE_NOTE_DESCRIPTION_MSG: "PLEASE_NOTE_DESCRIPTION_MSG",
  INTEREST_RATE_NOTE_1: "INTEREST_RATE_NOTE_1",
  INTEREST_RATE_NOTE_2: "INTEREST_RATE_NOTE_2",
  INTEREST_RATE_NOTE_3: "INTEREST_RATE_NOTE_3",
  INTEREST_RATE_NOTE_4: "INTEREST_RATE_NOTE_4",
  EMI_CALCULATION_NOTE: "EMI_CALCULATION_NOTE",
  CHANGING_EMI_CONFIG_MODAL_MSG: "CHANGING_EMI_CONFIG_MODAL_MSG",
  DO_YOU_WANT_TO_CONTINUE_MSG: "DO_YOU_WANT_TO_CONTINUE_MSG",
  PLEASE_ENTER_DOWNPAYMENT: "PLEASE_ENTER_DOWNPAYMENT",
  PLEASE_ENTER_BALLOON_PERCENTAGE: "PLEASE_ENTER_BALLOON_PERCENTAGE",
  PLEASE_ENTER_PROFIT_RATE: "PLEASE_ENTER_PROFIT_RATE",
  PLEASE_ENTER_INSURANCE: "PLEASE_ENTER_INSURANCE",
  PLEASE_ENTER_DURATION: "PLEASE_ENTER_DURATION",
  PLEASE_ENTER_ADMIN_FEES: "PLEASE_ENTER_ADMIN_FEES",
  PLEASE_ENTER_DEPRECIATED_PERCENT: "PLEASE_ENTER_DEPRECIATED_PERCENT",
  EMI_CONFIG_SAVED_SUCCESS_MSG: "EMI_CONFIG_SAVED_SUCCESS_MSG",
  EMI_CONFIGURATION: "EMI_CONFIGURATION",
  ALLOW_ONLY_NUMBER: "ALLOW_ONLY_NUMBER",
  PLEASE_ENTER_3_DIGIT: "PLEASE_ENTER_3_DIGIT",
  SET_IMAGE_FOR_MOBILE: "SET_IMAGE_FOR_MOBILE",
  SET_IMAGE_FOR_WEB: "SET_IMAGE_FOR_WEB",
  PLEASE_ENTER_15_DIGIT: "PLEASE_ENTER_15_DIGIT",
  STATUS_CONFIRMATION: "STATUS_CONFIRMATION",
  MANUFACTURE_IMAGE: "MANUFACTURE_IMAGE",
  INVALID_PLATE_NUMBER: "INVALID_PLATE_NUMBER",
  ENTER_YEAR: "ENTER_YEAR",
  ENTER_MAKE: "ENTER_MAKE",
  ENTER_MODEL: "ENTER_MODEL",
  ENTER_TRIM: "ENTER_TRIM",
  MAX_EMI_PRICE_IN_VEHICLE_FILTER: "MAX_EMI_PRICE_IN_VEHICLE_FILTER",
  MIN_EMI_PRICE_IN_VEHICLE_FILTER: "MIN_EMI_PRICE_IN_VEHICLE_FILTER",
  MAX_PRICE_GREATER_THAN_MIN: "MAX_PRICE_GREATER_THAN_MIN",
  MIN_MAX_EMI_CONFIGURATION: "MIN_MAX_EMI_CONFIGURATION",
  MAX_EMI_PRICE_CONFIGURE_MSG: "MAX_EMI_PRICE_CONFIGURE_MSG",
  IS_OUTLET: "IS_OUTLET",
  BEFORE_IMAGE: "BEFORE_IMAGE",
  AFTER_IMAGE: "AFTER_IMAGE",
  SAVE_DESCRIPTION: "SAVE_DESCRIPTION",
  BEFORE_AFTER_COMPARE_IMG: "BEFORE_AFTER_COMPARE_IMG",
  ALERT_DISCLAIMER_CONFIGURATION: "ALERT_DISCLAIMER_CONFIGURATION",
  VERBOSE: "VERBOSE",
  CATEGORY: "CATEGORY",
  ORDER_SUMMARY: "ORDER_SUMMARY",
  VAT: "VAT",
  GENERATE_PAYMENT_LINK: "GENERATE_PAYMENT_LINK",
  LIST_VALUES: "LIST_VALUES",
  IS_OUTLET_LABEL: "IS_OUTLET_LABEL",
  IS_OUTLET_LABEL_DESCRIPTION: "IS_OUTLET_LABEL_DESCRIPTION",
  TOTAL_QUOTATION_AMOUNT: "TOTAL_QUOTATION_AMOUNT",
  INCLUSIVE_VAT: "INCLUSIVE_VAT",
  PLEASE_ENTER_2_DIGIT: "PLEASE_ENTER_2_DIGIT",
  VALUE_MORE_THAN_100: "VALUE_MORE_THAN_100",
  DOWNPAYMENT_MORE_THAN_100: "DOWNPAYMENT_MORE_THAN_100",
  BALLOON_PAYMENT_MORE_THAN_100: "BALLOON_PAYMENT_MORE_THAN_100",
  PROFIT_RATE_MORE_THAN_100: "PROFIT_RATE_MORE_THAN_100",
  INSURANCE_MORE_THAN_100: "INSURANCE_MORE_THAN_100",
  ADMIN_FEES_MORE_THAN_100: "ADMIN_FEES_MORE_THAN_100",
  DEPRECIATED_PERCENT_MORE_THAN_100: "DEPRECIATED_PERCENT_MORE_THAN_100",
  SYSTEM_CONFIGURATION: "SYSTEM_CONFIGURATION",
  EML_OTP_LENGTH: "EML_OTP_LENGTH",
  ALLOWED_DAYS: "ALLOWED_DAYS",
  CHECK_AWS_PRICE_LOW_THRESHOLD: "CHECK_AWS_PRICE_LOW_THRESHOLD",
  CHECK_AWS_PRICE_HIGH_THRESHOLD: "CHECK_AWS_PRICE_HIGH_THRESHOLD",
  ERROR_BETWEEN_ZERO_AND_HUNDRED: "ERROR_BETWEEN_ZERO_AND_HUNDRED",
  CONFIRM_LIST_MSG: "CONFIRM_LIST_MSG",
  SMS: "SMS",
  INVALID_MOBILE_NUMBER: "INVALID_MOBILE_NUMBER",
  COUNTRY_CODE_LABEL: "COUNTRY_CODE_LABEL",
  DOWN_PAYMENT: "DOWN_PAYMENT",
  INSURANCE: "INSURANCE",
  PROFIT_RATE: "PROFIT_RATE",
  DURATION_OF_LOAN: "DURATION_OF_LOAN",
  ADMIN_FEES: "ADMIN_FEES",
  DEPRECIATED_PERCENTAGE: "DEPRECIATED_PERCENTAGE",
  MANAGE_PACKAGE_SEQUENCE: "MANAGE_PACKAGE_SEQUENCE",
  MANAGE_SERVICE_SEQUENCE: "MANAGE_SERVICE_SEQUENCE",
  CUSTOMER_SUPPORT_EMAIL: "CUSTOMER_SUPPORT_EMAIL",
  PE_LOCATION_ADDRESS: "PE_LOCATION_ADDRESS",
  CURRENTLY_NO_ACTIVE_PACKAGE_MSG: "CURRENTLY_NO_ACTIVE_PACKAGE_MSG",
  MERCHANT_REFERENCE_NUMBER: "MERCHANT_REFERENCE_NUMBER",
  LBL_EMAIL_TEMPLATE: "LBL_EMAIL_TEMPLATE",
  TEMPLATE_NAME: "TEMPLATE_NAME",
  EDIT_EMAIL_TEMPLATE: "EDIT_EMAIL_TEMPLATE",
  EMAIL_SUBJECT: "EMAIL_SUBJECT",
  EMAIL_BODY: "EMAIL_BODY",
  TEMPLATE: "TEMPLATE",
  LANGUAGE: "LANGUAGE",
  TAGS: "TAGS",
  INCLUDE_HEADER: "INCLUDE_HEADER",
  INCLUDE_FOOTER: "INCLUDE_FOOTER",
  EDIT_COMMON_TEMPLATE: "EDIT_COMMON_TEMPLATE",
  HEADER: "HEADER",
  FOOTER: "FOOTER",
  EMAIL_TEMPLATE_DEACTIVATE: "EMAIL_TEMPLATE_DEACTIVATE",
  EMAIL_TEMPLATE_ACTIVATE: "EMAIL_TEMPLATE_ACTIVATE",
  LBL_SMS_NOTIFICATION: "LBL_SMS_NOTIFICATION",
  EDIT_SMS_NOTIFICATION: "EDIT_SMS_NOTIFICATION",
  LBL_ALERT_NOTIFICATION: "LBL_ALERT_NOTIFICATION",
  EDIT_ALERT_NOTIFICATION: "EDIT_ALERT_NOTIFICATION",
  ALERT_TEXT: "ALERT_TEXT",
  LBL_WHATSAPP_NOTIFICATION: "LBL_WHATSAPP_NOTIFICATION",
  EDIT_WHATSAPP_NOTIFICATION: "EDIT_WHATSAPP_NOTIFICATION",
  LBL_MOBILE_NOTIFICATION: "LBL_MOBILE_NOTIFICATION",
  EDIT_MOBILE_NOTIFICATION: "EDIT_MOBILE_NOTIFICATION",
  TITLE: "TITLE",
  SEND_PREVIEW: "SEND_PREVIEW",
  IS_APPLIED_AND_DELETED: "IS_APPLIED_AND_DELETED",
  LOCATION_NAME: "LOCATION_NAME",
  LOCATION_NAME_STATUS_ACTIVATED: "LOCATION_NAME_STATUS_ACTIVATED",
  LOCATION_NAME_STATUS_DEACTIVATED: "LOCATION_NAME_STATUS_DEACTIVATED",
  UPDATE_LOCATION_NAME_STATUS: "UPDATE_LOCATION_NAME_STATUS",
  ADD_LOCATION_NAME_STATUS: "ADD_LOCATION_NAME_STATUS",
  LOCATION_NAME_ACTIVATE_MSG: "LOCATION_NAME_ACTIVATE_MSG",
  LOCATION_NAME_DEACTIVATE_MSG: "LOCATION_NAME_DEACTIVATE_MSG",
  MAP_URL: "MAP_URL",
  LOCATION_TYPE: "LOCATION_TYPE",
  VENDOR_LOCATION_NAME: "VENDOR_LOCATION_NAME",
  LOCATION_NAME_STATUS_UPDATED_SUCCESSFULLY:
    "LOCATION_NAME_STATUS_UPDATED_SUCCESSFULLY",
  LOCATION_NAME_STATUS_CREATED_SUCCESSFULLY:
    "LOCATION_NAME_STATUS_CREATED_SUCCESSFULLY",
  ENTER_MAP_URL: "ENTER_MAP_URL",
  SELECT_ADDRESS_TYPE: "SELECT_ADDRESS_TYPE",
  ENTER_VENDOR_LOCATION: "ENTER_VENDOR_LOCATION",
  SAVE_LOCATION_DETAILS_ALREADY_EXSISTS:
    "SAVE_LOCATION_DETAILS_ALREADY_EXSISTS",
  YOUR_ASKING_PRICE: "YOUR_ASKING_PRICE",
  THE_REQUESTED_VALUE_IN_RANGE_MARKET_VALUE:
    "THE_REQUESTED_VALUE_IN_RANGE_MARKET_VALUE",
  YOUR_VEHICLE_PRICE_IS: "YOUR_VEHICLE_PRICE_IS",
  LESS_THAN_MARKET_PRICE: "LESS_THAN_MARKET_PRICE",
  MORE_THAN_MARKET_PRICE: "MORE_THAN_MARKET_PRICE",
  TOO_LOW_THAN_MARKET_PRICE: "TOO_LOW_THAN_MARKET_PRICE",
  TOO_HIGH_THAN_MARKET_PRICE: "TOO_HIGH_THAN_MARKET_PRICE",
  GENERIC_ERROR_MY_CAR_VALUE: "GENERIC_ERROR_MY_CAR_VALUE",
  CHANGE_PRICE: "CHANGE_PRICE",
  ALTERNATE_EMAIL_SAME_AS_PRIMARY: "ALTERNATE_EMAIL_SAME_AS_PRIMARY",
  BODY_PARAMETER: "BODY_PARAMETER",
  SUBJECT_PARAMETER: "SUBJECT_PARAMETER",
  RV_APPROVE_VEHICLES: "RV_APPROVE_VEHICLES",
  VIN_NOT_DECODED: "VIN_NOT_DECODED",
  VIN_DECODED_SUCCESSFULLY: "VIN_DECODED_SUCCESSFULLY",
  AWAITING_RV_VEHICLE: "AWAITING_RV_VEHICLE",
  EXTERNAL: "EXTERNAL",
  INTERNAL: "INTERNAL",
  BOOT_ENGINE: "BOOT_ENGINE",
  OTHER: "OTHER",
  UP_TO_EIGHT_PHOTOS: "UP_TO_EIGHT_PHOTOS",
  UPLOAD_VIN_NOTE: "UPLOAD_VIN_NOTE",
  FILE_UPLOAD_SUCCESS_MSG: "FILE_UPLOAD_SUCCESS_MSG",
  CUSTOMER_NAME: "CUSTOMER_NAME",
  CONTACT_NUMBER: "CONTACT_NUMBER",
  BOOKING_REF: "BOOKING_REF",
  APPOINTMENT_DATE: "APPOINTMENT_DATE",
  APPOINTMENT_TIME: "APPOINTMENT_TIME",
  APPOINTMENT_DETAILS_LBL: "APPOINTMENT_DETAILS_LBL",
  RESCHEDULE_APPOINTMENT: "RESCHEDULE_APPOINTMENT",
  INSPECTION_REBOOKING_SUCCESS: "INSPECTION_REBOOKING_SUCCESS",
  INSPECTION_REBOOKING_NOT_ALLOWED: "INSPECTION_REBOOKING_NOT_ALLOWED",
  INSPECTION_REBOOKING_FAILURE: "INSPECTION_REBOOKING_FAILURE",
  UPDATE_LOCATION_DETAILS: "UPDATE_LOCATION_DETAILS",
  ADD_LOCATION_DETAILS: "ADD_LOCATION_DETAILS",
  TOTAL_APPOINTMENTS: "TOTAL_APPOINTMENTS",
  ADVISOR_NAME: "ADVISOR_NAME",
  ADVISOR_CONTACT: "ADVISOR_CONTACT",
  ADVISOR_EMAIL: "ADVISOR_EMAIL",
  LATITUDE: "LATITUDE",
  LONGITUDE: "LONGITUDE",
  ONLY_NUMBERS_DECIMAL: "ONLY_NUMBERS_DECIMAL",
  LOCATION_DETAILS: "LOCATION_DETAILS",
  SEARCH_FILTER_TOOLTIP: "SEARCH_FILTER_TOOLTIP",
  UPLOAD_VIN_NOTE_DEALER: "UPLOAD_VIN_NOTE_DEALER",
  ADDRESS_TYPE: "ADDRESS_TYPE",
  LOCATION_NAME_DELETE_MSG: "LOCATION_NAME_DELETE_MSG",
  LOCATION_DELETE_SUCCESS_MSG: "LOCATION_DELETE_SUCCESS_MSG",
  SHOW_DELETED_LOCATION: "SHOW_DELETED_LOCATION",
  DEALER_DEACTIVATION_FAILED_VEHICLES_FOUND:
    "DEALER_DEACTIVATION_FAILED_VEHICLES_FOUND",
  DEALER_DEACTIVATION_FAILED_SALESPERSONS_FOUND:
    "DEALER_DEACTIVATION_FAILED_SALESPERSONS_FOUND",
  DEALER_DEACTIVATION_FAILED: "DEALER_DEACTIVATION_FAILED",
  DEALER_DEACTIVATION_SUCCESS: "DEALER_DEACTIVATION_SUCCESS",
  CANCEL_APPOINTMENT_MSG: "CANCEL_APPOINTMENT_MSG",
  CANCEL_APPOINTMENT_SUCCESS_MSG: "CANCEL_APPOINTMENT_SUCCESS_MSG",
  BY_PASS_PACKAGE: "BY_PASS_PACKAGE",
  AUTO_RELEASE_VEHICLE: "AUTO_RELEASE_VEHICLE",
  INVALID_COMBINATION: "INVALID_COMBINATION",
  VEHICLE_SENT_QC: "VEHICLE_SENT_QC",
  VEHICLE_SENT_QC_ERROR: "VEHICLE_SENT_QC_ERROR",
  DMS_FILTER_QC_REQUEST_FOR_A_CHANGE: "DMS_FILTER_QC_REQUEST_FOR_A_CHANGE",
  QC_REQUEST_FOR_A_CHANGE: "QC_REQUEST_FOR_A_CHANGE",
  CANCEL_DOCUMENT_MSG: "CANCEL_DOCUMENT_MSG",
  CANCEL_DOCUMENT_SUCCESS_MSG: "CANCEL_DOCUMENT_SUCCESS_MSG",
  VEHICLE_JOURNEY: "VEHICLE_JOURNEY",
  SOURCE_OF_VEHICLE: "SOURCE_OF_VEHICLE",
  BROCHURE_DOCUMENTS: "BROCHURE_DOCUMENTS",
  VEHICLE_REJECT: "VEHICLE_REJECT",
  ENTER_TEXT_CHANGE_REASON: "ENTER_TEXT_CHANGE_REASON",
  VEHICLE_REJECTION_REASON: "VEHICLE_REJECTION_REASON",
  OUTLET: "OUTLET",
  NO_DOCUMENT_FOUND_MSG: "NO_DOCUMENT_FOUND_MSG",
  ENTER_TITLE: "ENTER_TITLE",
  PLEASE_UPLOAD_FILE_FOR_PREVIOUS: "PLEASE_UPLOAD_FILE_FOR_PREVIOUS",
  DOCUMENT_UPDATE_SUCCESS_MSG: "DOCUMENT_UPDATE_SUCCESS_MSG",
  VEHICLE_IMAGES: "VEHICLE_IMAGES",
  QC_ADD_REASON_WARNING: "QC_ADD_REASON_WARNING",
  DEALER_NEED_CHANGES: "DEALER_NEED_CHANGES",
  NOTE_FOR_DEALER: "NOTE_FOR_DEALER",
  OWNER_NAME_REQUIRED: "OWNER_NAME_REQUIRED",
  EDIT_DEALER: "EDIT_DEALER",
  MAKE_MODEL_PRICE_VALIDATION_MSG: "MAKE_MODEL_PRICE_VALIDATION_MSG",
  DEALER_UPDATE_SUCCESS_MSG: "DEALER_UPDATE_SUCCESS_MSG",
  DEALER_UPDATE_FAILED_MSG: "DEALER_UPDATE_FAILED_MSG",
  DEALER_NAME_REQUIRED: "DEALER_NAME_REQUIRED",
  SECONDARY_EMAIL_SAME_AS_PRIMARY: "SECONDARY_EMAIL_SAME_AS_PRIMARY",
  SEARCH_USER_FILTER_TOOLTIP: "SEARCH_USER_FILTER_TOOLTIP",
  PUSH_NOTIFICATION: "PUSH_NOTIFICATION",
  FCM_NOTIFICATION_SUCCESS: "FCM_NOTIFICATION_SUCCESS",
  ENTER_NOTIFICATION_TITLE: "ENTER_NOTIFICATION_TITLE",
  ENTER_MESSAGE: "ENTER_MESSAGE",
  LBL_FAQ_CATEGORY: "LBL_FAQ_CATEGORY",
  ADD_FAQ_CATEGORY: "ADD_FAQ_CATEGORY",
  ADD_FAQ: "ADD_FAQ",
  FAQ: "FAQ",
  LBL_FAQ_CATEGORY_ARABIC: "LBL_FAQ_CATEGORY_ARABIC",
  EDIT_FAQ_CATEGORY: "EDIT_FAQ_CATEGORY",
  FAQ_SAVED_SUCCESSFULLY: "FAQ_SAVED_SUCCESSFULLY",
  ENTER_FAQ_CATEGORY: "ENTER_FAQ_CATEGORY",
  REPORTS: "REPORTS",
  ADD_REPORT: "ADD_REPORT",
  UPDATE_REPORT: "UPDATE_REPORT",
  PARAMETERS: "PARAMETERS",
  REPORTTYPE: "REPORTTYPE",
  DYNAMIC_REPORT_CREATED_SUCCESSFULLY: "DYNAMIC_REPORT_CREATED_SUCCESSFULLY",
  DYNAMIC_REPORT__UPDATED_SUCCESSFULLY: "DYNAMIC_REPORT__UPDATED_SUCCESSFULLY",
  DYNAMIC_REPORT_SAVE_FAILURE: "DYNAMIC_REPORT_SAVE_FAILURE",
  DYNAMIC_REPORT_UPDATE_FAILURE: "DYNAMIC_REPORT_UPDATE_FAILURE",
  REPORT_DELETED_SUCCESS: "REPORT_DELETED_SUCCESS",
  REPORT_DELETE_MSG: "REPORT_DELETE_MSG",
  VIEW_REPORT: "VIEW_REPORT",
  VERIFY_QUERY: "VERIFY_QUERY",
  ADD_FAQ_MAX_CHARACTER: "ADD_FAQ_MAX_CHARACTER",
  LBL_QUESTION: "LBL_QUESTION",
  EDIT_FAQ_TEMPLATE: "EDIT_FAQ_TEMPLATE",
  ADD_FAQ_TEMPLATE: "ADD_FAQ_TEMPLATE",
  CREATE_FAQ_TEMPLATE: "CREATE_FAQ_TEMPLATE",
  CREATE_EMAIL_TEMPLATE: "CREATE_EMAIL_TEMPLATE",
  FAQ_SAVED_SUCCESS_MSG: "FAQ_SAVED_SUCCESS_MSG",
  FAQ_CATEGORY_DELETE_MSG: "FAQ_CATEGORY_DELETE_MSG",
  FAQ_CATEGORY_DELETE_SUCCESS_MSG: "FAQ_CATEGORY_DELETE_SUCCESS_MSG",
  FAQ_LIST_DELETE_MSG: "FAQ_LIST_DELETE_MSG",
  FAQ_LIST_DELETE_SUCCESS_MSG: "FAQ_LIST_DELETE_SUCCESS_MSG",
  FRM_INFO_MSG_APPROVE_SUBMIT: "FRM_INFO_MSG_APPROVE_SUBMIT",
  FRM_INFO_MSG_CHANGE_REQUEST_SUBMIT: "FRM_INFO_MSG_CHANGE_REQUEST_SUBMIT",
  SPIN_VIDEO_TITLE: "SPIN_VIDEO_TITLE",
  E_BROCHURE_FILE_FORMAT: "E_BROCHURE_FILE_FORMAT",
  BULK_UPLOAD_CAR_TYPE: "BULK_UPLOAD_CAR_TYPE",
  IS_SELF_LISTED_PACKAGE: "IS_SELF_LISTED_PACKAGE",
  PACKAGE_TO_MAP: "PACKAGE_TO_MAP",
  PACKAGE_CAN_UPGRADE_TO: "PACKAGE_CAN_UPGRADE_TO",
  PACKAGE_MAPPED_SUCCESSFULLY: "PACKAGE_MAPPED_SUCCESSFULLY",
  ERROR_PACKAGE_MAPPED: "ERROR_PACKAGE_MAPPED",
  MAP_UPGRADABLE_PACKAGE: "MAP_UPGRADABLE_PACKAGE",
  LOAN_TENURE: "LOAN_TENURE",
  ADMINISTRATIVE_FEES_PERCENTAGE: "ADMINISTRATIVE_FEES_PERCENTAGE",
  ADMINISTRATIVE_MAXIMUM_AMOUNT: "ADMINISTRATIVE_MAXIMUM_AMOUNT",
  NATIONALITY: "NATIONALITY",
  BIRTH_YEAR: "BIRTH_YEAR",
  GENDER: "GENDER",
  NET_INCOME_PER_MONTH: "NET_INCOME_PER_MONTH",
  BANK_SALARY_DEPOSIT: "BANK_SALARY_DEPOSIT",
  JOB_DURATION: "JOB_DURATION",
  LABEL: "LABEL",
  TRANSLATIONS: "TRANSLATIONS",
  SEARCH_TRANSLATION_TOOLTIP: "SEARCH_TRANSLATION_TOOLTIP",
  ADD_TRANSLATION: "ADD_TRANSLATION",
  UPDATE_TRANSLATION: "UPDATE_TRANSLATION",
  TRANSLATION_SAVED_SUCCESS_MSG: "TRANSLATION_SAVED_SUCCESS_MSG",
  TRANSLATION_STATUS_SUCCESS_MSG: "TRANSLATION_STATUS_SUCCESS_MSG",
  TRANSLATION_STATUS_UPDATE: "TRANSLATION_STATUS_UPDATE",
  CONTEXT: "CONTEXT",
  CREATED_START_DATE: "CREATED_START_DATE",
  CREATED_END_DATE: "CREATED_END_DATE",
  SERIAL_NO_FROM: "SERIAL_NO_FROM",
  SERIAL_NO_TO: "SERIAL_NO_TO",
  TRANSLATION_ID: "TRANSLATION_ID",
  UPDATED_BY: "UPDATED_BY",
  AUTH_CODE_ERROR: "AUTH_CODE_ERROR",
  ADD_USERS: "ADD_USERS",
  FAV: "FAV",
  EXPORT_JSON: "EXPORT_JSON",
  DUPLICATE_LABEL_ERROR: "DUPLICATE_LABEL_ERROR",
  EMAIL_CC: "EMAIL_CC",
  EMAIL_BCC: "EMAIL_BCC",
  WORKING_SECTOR: "WORKING_SECTOR",
  WORKING_SECTOR_ACTIVATE_MSG: "WORKING_SECTOR_ACTIVATE_MSG",
  WORKING_SECTOR_DEACTIVATE_MSG: "WORKING_SECTOR_DEACTIVATE_MSG",
  WORKING_SECTOR_CREATED_SUCCESSFULLY: "WORKING_SECTOR_CREATED_SUCCESSFULLY",
  WORKING_SECTOR_UPDATED_SUCCESSFULLY: "WORKING_SECTOR_UPDATED_SUCCESSFULLY",
  UPDATE_WORKING_SECTOR: "UPDATE_WORKING_SECTOR",
  ADD_WORKING_SECTOR: "ADD_WORKING_SECTOR",
  WORKING_SECTOR_SAVE_FAILED_MSG: "WORKING_SECTOR_SAVE_FAILED_MSG",
  WORKING_SECTOR_ACTIVATED: "WORKING_SECTOR_ACTIVATED",
  WORKING_SECTOR_DEACTIVATED: "WORKING_SECTOR_DEACTIVATED",
  BANK_DETAILS: "BANK_DETAILS",
  BANK_DETAILS_ACTIVATE_MSG: "BANK_DETAILS_ACTIVATE_MSG",
  BANK_DETAILS_DEACTIVATE_MSG: "BANK_DETAILS_DEACTIVATE_MSG",
  BANK_DETAILS_CREATED_SUCCESSFULLY: "BANK_DETAILS_CREATED_SUCCESSFULLY",
  BANK_DETAILS_UPDATED_SUCCESSFULLY: "BANK_DETAILS_UPDATED_SUCCESSFULLY",
  UPDATE_BANK_DETAILS: "UPDATE_BANK_DETAILS",
  ADD_BANK_DETAILS: "ADD_BANK_DETAILS",
  BANK_DETAILS_SAVE_FAILED_MSG: "BANK_DETAILS_SAVE_FAILED_MSG",
  BANK_DETAILS_ACTIVATED: "BANK_DETAILS_ACTIVATED",
  BANK_DETAILS_DEACTIVATED: "BANK_DETAILS_DEACTIVATED",
  BANK_NAME: "BANK_NAME",
  RESULTS: "RESULTS",
  EMI_CALCULATOR_URL: "EMI_CALCULATOR_URL",
  ENTER_VALID_URL: "ENTER_VALID_URL",
  EMI_URL: "EMI_URL",
  GGM_DIGITAL_DEALER: "GGM_DIGITAL_DEALER",
  SALES_CONTACT: "SALES_CONTACT",
  DEALER_SALES_CONTACTS: "DEALER_SALES_CONTACTS",
  MANAGE_MAKE: "MANAGE_MAKE",
  MANAGE_MODEL: "MANAGE_MODEL",
  MAKE_KEY: "MAKE_KEY",
  MODEL_KEY: "MODEL_KEY",
  MAKE_CREATED_SUCCESSFULLY: "MAKE_CREATED_SUCCESSFULLY",
  MAKE_UPDATED_SUCCESSFULLY: "MAKE_UPDATED_SUCCESSFULLY",
  UPDATE_MAKE: "UPDATE_MAKE",
  ADD_MAKE: "ADD_MAKE",
  MAKE_SAVE_FAILED_MSG: "MAKE_SAVE_FAILED_MSG",
  MODEL_CREATED_SUCCESSFULLY: "MODEL_CREATED_SUCCESSFULLY",
  MODEL_UPDATED_SUCCESSFULLY: "MODEL_UPDATED_SUCCESSFULLY",
  UPDATE_MODEL: "UPDATE_MODEL",
  ADD_MODEL: "ADD_MODEL",
  MODEL_SAVE_FAILED_MSG: "MODEL_SAVE_FAILED_MSG",
  UPLOAD_BANNER_IMAGES: "UPLOAD_BANNER_IMAGES",
  SELECT_DISTRIBUTOR: "SELECT_DISTRIBUTOR",
  HERO_IMAGE_FOR_WEB: "HERO_IMAGE_FOR_WEB",
  HERO_IMAGE_FOR_MOBILE: "HERO_IMAGE_FOR_MOBILE",
  IS_STOCK_RESERVED: "IS_STOCK_RESERVED",
  UPLOAD_LOGOS: "UPLOAD_LOGOS",
  COLORED_LOGO: "COLORED_LOGO",
  BLACK_AND_WHITE_LOGO: "BLACK_AND_WHITE_LOGO",
  ACTUAL_LOGO_IMAGE_CAN_BE_USED: "ACTUAL_LOGO_IMAGE_CAN_BE_USED",
  GREYSCALE_LOGO: "GREYSCALE_LOGO",
  DEFAULT_EMI_PERSONA: "DEFAULT_EMI_PERSONA",
  MANAGE_MODEL_IMAGE_NOTE: "MANAGE_MODEL_IMAGE_NOTE",
  MANAGE_COLOR: "MANAGE_COLOR",
  ADD_NEW_COLOR: "ADD_NEW_COLOR",
  MANAGE_VARIANT: "MANAGE_VARIANT",
  ADD_VARIANT: "ADD_VARIANT",
  VARIANT: "VARIANT",
  ERROR_SAVE_DATA: "ERROR_SAVE_DATA",
  EDIT_COLOR: "EDIT_COLOR",
  EDIT_VARIANT: "EDIT_VARIANT",
  HEX_CODE: "HEX_CODE",
  ADD_VEHICLE_PROFILE: "ADD_VEHICLE_PROFILE",
  INSURANCE_PERCENTAGE: "INSURANCE_PERCENTAGE",
  NEW_CAR_DEPRECIATED_PERCENTAGE: "NEW_CAR_DEPRECIATED_PERCENTAGE",
  VALIDATION_MSG_PROFILE_LINKED_TO_ANY_VEHICLE_LISTING:
    "VALIDATION_MSG_PROFILE_LINKED_TO_ANY_VEHICLE_LISTING",
  EDIT_VEHICLE_PROFILE_FEATURES: "EDIT_VEHICLE_PROFILE_FEATURES",
  MANUFACTURER_VEHICLE_PROFILE: "MANUFACTURER_VEHICLE_PROFILE",
  EDIT_VEHICLE_PROFILE_SPECS: "EDIT_VEHICLE_PROFILE_SPECS",
  SPECIFICATIONS: "SPECIFICATIONS",
  IMAGES_VIDEOS: "IMAGES_VIDEOS",
  EDIT_DOCUMENTS: "EDIT_DOCUMENTS",
  SELECT_BASE_COLOR: "SELECT_BASE_COLOR",
  VIDEOS: "VIDEOS",
  ENTER_VIDEO_URL: "ENTER_VIDEO_URL",
  USER_ROLE: "USER_ROLE",
  USER_ROLE_KEY: "USER_ROLE_KEY",
  ADD_USER_ROLE: "ADD_USER_ROLE",
  UPDATE_USER_ROLE: "UPDATE_USER_ROLE",
  ROLE_NAME: "ROLE_NAME",
  ROLE_ACTIVATE_MSG: "ROLE_ACTIVATE_MSG",
  ROLE_DEACTIVATE_MSG: "ROLE_DEACTIVATE_MSG",
  ROLE_ACTIVATED: "ROLE_ACTIVATED",
  ROLE_DEACTIVATED: "ROLE_DEACTIVATED",
  SEARCH_ROLE_TOOLTIP: "SEARCH_ROLE_TOOLTIP",
  FROM_DATE: "FROM_DATE",
  TO_DATE: "TO_DATE",
  KEY: "KEY",
  CANNOT_CONTAIN_WHITE_SPACE: "CANNOT_CONTAIN_WHITE_SPACE",
  ROLE_UPDATED_SUCCESS_MSG: "ROLE_UPDATED_SUCCESS_MSG",
  ROLE_SAVED_SUCCESS_MSG: "ROLE_SAVED_SUCCESS_MSG",
  KEY_NAME_ALREADY_EXIST_MSG: "KEY_NAME_ALREADY_EXIST_MSG",
  MENU_LABEL: "MENU_LABEL",
  MENU_UPDATE_SUCCESS_MSG: "MENU_UPDATE_SUCCESS_MSG",
  IMPORT: "IMPORT",
  EDIT_MODEL_FEATURES: "EDIT_MODEL_FEATURES",
  EDIT_MODEL_SPECS: "EDIT_MODEL_SPECS",
  EDIT_VARIANT_FEATURES: "EDIT_VARIANT_FEATURES",
  EDIT_VARIANT_SPECS: "EDIT_VARIANT_SPECS",
  SUMMARY: "SUMMARY",
  PLEASE_SELECT_PDF_FILE: "PLEASE_SELECT_PDF_FILE",
  VIDEO_URL: "VIDEO_URL",
  PLEASE_SELECT_BASE_COLOR: "PLEASE_SELECT_BASE_COLOR",
  DEALS_LABEL: "DEALS_LABEL",
  DEAL_NUMBER: "DEAL_NUMBER",
  BUYER_ORDER_NUMBER: "BUYER_ORDER_NUMBER",
  DEAL_CREATED_DATE: "DEAL_CREATED_DATE",
  DEAL_STATUS: "DEAL_STATUS",
  DEAL_HISTORY: "DEAL_HISTORY",
  STATUS_UPDATED_SUCCESSFULLY: "STATUS_UPDATED_SUCCESSFULLY",
  DOCUMENT_UPLOAD_NOTE: "DOCUMENT_UPLOAD_NOTE",
  FEATURE_CONFIRM_MSG: "FEATURE_CONFIRM_MSG",
  SPECIFICATION_CONFIRM_MSG: "SPECIFICATION_CONFIRM_MSG",
  BULK_ASSIGN: "BULK_ASSIGN",
  ADD_MISSING_FIELDS: "ADD_MISSING_FIELDS",
  OEM_IMAGES: "OEM_IMAGES",
  UPLOAD_DATE: "UPLOAD_DATE",
  BULK_ASSIGN_MESSAGE: "BULK_ASSIGN_MESSAGE",
  DO_NOT_USED_BRAND: "DO_NOT_USED_BRAND",
  SELECT_TAG: "SELECT_TAG",
  PRIVILEGES: "PRIVILEGES",
  PRIVILEGE_LABEL: "PRIVILEGE_LABEL",
  TOTAL_PENDING_REQ_MSG: "TOTAL_PENDING_REQ_MSG",
  CLICK_TO_REVIEW: "CLICK_TO_REVIEW",
  TAG_IS_NOT_PRESENT: "TAG_IS_NOT_PRESENT",
  TAGS_ARE_NOT_PRESENT: "TAGS_ARE_NOT_PRESENT",
  PRIVILEGE_UPDATE_SUCCESS_MSG: "PRIVILEGE_UPDATE_SUCCESS_MSG",
  PRIVILEGE_ACTIVATE_MSG: "PRIVILEGE_ACTIVATE_MSG",
  PRIVILEGE_DEACTIVATE_MSG: "PRIVILEGE_DEACTIVATE_MSG",
  PRIVILEGE_ACTIVATED: "PRIVILEGE_ACTIVATED",
  PRIVILEGE_DEACTIVATED: "PRIVILEGE_DEACTIVATED",
  ADD_PRIVILEGE: "ADD_PRIVILEGE",
  UPDATE_PRIVILEGE: "UPDATE_PRIVILEGE",
  PRIVILEGE_ADD_SUCCESS_MSG: "PRIVILEGE_ADD_SUCCESS_MSG",
  PRIVILEGE_NAME: "PRIVILEGE_NAME",
  SEARCH_PRIVILEGE_TOOLTIP: "SEARCH_PRIVILEGE_TOOLTIP",
  COLOR_VARIANT: "COLOR_VARIANT",
  MANUFACTURER_WARRANTY: "MANUFACTURER_WARRANTY",
  MANUFACTURER_WARRANTY_DESCRIPTION: "MANUFACTURER_WARRANTY_DESCRIPTION",
  IMPORTED_BY: "IMPORTED_BY",
  IMPORTER_NAME: "IMPORTER_NAME",
  SUPPLIED_BY: "SUPPLIED_BY",
  SUPPLIER_NAME: "SUPPLIER_NAME",
  VEHICLE_PROFILE_LABEL: "VEHICLE_PROFILE_LABEL",
  SUPPLIER_ACTIVATE_MSG: "SUPPLIER_ACTIVATE_MSG",
  SUPPLIER_DEACTIVATE_MSG: "SUPPLIER_DEACTIVATE_MSG",
  SUPPLIER_CREATED_SUCCESSFULLY: "SUPPLIER_CREATED_SUCCESSFULLY",
  SUPPLIER_UPDATED_SUCCESSFULLY: "SUPPLIER_UPDATED_SUCCESSFULLY",
  UPDATE_SUPPLIER: "UPDATE_SUPPLIER",
  ADD_SUPPLIER: "ADD_SUPPLIER",
  SUPPLIER_SAVE_FAILED_MSG: "SUPPLIER_SAVE_FAILED_MSG",
  SUPPLIER_ACTIVATED: "SUPPLIER_ACTIVATED",
  SUPPLIER_DEACTIVATED: "SUPPLIER_DEACTIVATED",
  IS_CAPTIVE: "IS_CAPTIVE",
  BROWSE_NEW_OUTLET_FILE: "BROWSE_NEW_OUTLET_FILE",
  NEW_OUTLET_CAR: "NEW_OUTLET_CAR",
  NO_VIDEOS_FOUND_MSG: "NO_VIDEOS_FOUND_MSG",
  INVALID_BOOKING_DAYS: "INVALID_BOOKING_DAYS",
  REGISTRATION_FEES: "REGISTRATION_FEES",
  INVOICE_TYPE: "INVOICE_TYPE",
  CASH: "CASH",
  BANK: "BANK",
  WARRANTY_PERIOD: "WARRANTY_PERIOD",
  MILLAGE_COVERED: "MILLAGE_COVERED",
  PROFIT: "PROFIT",
};

export const I18N_NS = {
  DEFAULT: "translation",
  MSG_CODE: "api_msg_code",
};

export const API_MSG_CODE = {
  DEALER_APPLICATION_APPROVED: "DEALER_APPLICATION_APPROVED",
  DEALER_APPLICATION_UPDATED: "DEALER_APPLICATION_UPDATED",
  USER_CREATED: "USER_CREATED",
  EMAIL_ALREADY_EXISTS: "EMAIL_ALREADY_EXISTS",
  MOBILE_ALREADY_EXISTS: "MOBILE_ALREADY_EXISTS",
  USER_CREATION_FAILED: "USER_CREATION_FAILED",
  UPDATEPROFILE_SUCCESS_MSG: "UPDATEPROFILE_SUCCESS_MSG",
  VEHICLE_ASSIGN_SUCCESS_MSG: "VEHICLE_ASSIGN_SUCCESS_MSG",
  ACTIVE_MSG: "ACTIVATE_SUCESS_MSG",
  DEACTIVE_MSG: "DEACTIVATE_SUCESS_MSG",
  ACTIVATED: "ACTIVATED",
  DEACTIVATED: "DEACTIVATED",
  SAVE_SUCCESS: "SAVE_SUCCESS",
  SAVE_LEAD_DETAILS_SUCCESS_MSG: "SAVE_LEAD_DETAILS_SUCCESS_MSG",
  PACKAGE_CREATION_ERROR_MSG: "PACKAGE_CREATION_ERROR_MSG",
  PACKAGE_CONFIGURATION_ERROR_MSG: "PACKAGE_CONFIGURATION_ERROR_MSG",
  PACKAGE_CONFIGURATION_EXISTING_DATE_RANGE_ERROR_MSG:
    "PACKAGE_CONFIGURATION_EXISTING_DATE_RANGE_ERROR_MSG",
  PACKAGE_CONFIGURATION_SUCCESS_MSG: "PACKAGE_CONFIGURATION_SUCCESS_MSG",
  SHOPPING_CART_ITEM_REMOVED_ERR: "SHOPPING_CART_ITEM_REMOVED_ERR",
  VEHICLE_LISTED_SUCCESS: "VEHICLE_LISTED_SUCCESS",
  VEHICLE_DELETED: "VEHICLE_DELETED",
  BUYER_NOT_FOUND: "BUYER_NOT_FOUND",
  DOCUMENT_UPLOAD_SUCCESS_MSG: "DOCUMENT_UPLOAD_SUCCESS_MSG",
  DOCUMENT_UPLOAD_ERROR_MSG: "DOCUMENT_UPLOAD_ERROR_MSG",
  IMAGE_UPLOAD_SUCCESS_MSG: "IMAGE_UPLOAD_SUCCESS_MSG",
  IMAGE_UPLOAD_ERROR_MSG: "IMAGE_UPLOAD_ERROR_MSG",
  DOCUMENT_DELETE_SUCCESS_MSG: "DOCUMENT_DELETE_SUCCESS_MSG",
  DOCUMENT_DELETE_ERROR_MSG: "DOCUMENT_DELETE_ERROR_MSG",
  IMAGE_DELETE_SUCCESS_MSG: "IMAGE_DELETE_SUCCESS_MSG",
  IMAGE_DELETE_ERROR_MSG: "IMAGE_DELETE_ERROR_MSG",
  DELIST_VEHICLE_SUCCESS_MSG: "DELIST_VEHICLE_SUCCESS_MSG",
  DELIST_VEHICLE_ERROR_MSG: "DELIST_VEHICLE_ERROR_MSG",
  UPDATEPROFILE_ERROR_MSG: "UPDATEPROFILE_ERROR_MSG",
  REFUND_AMOUNT_EXCEEDED_MSG: "REFUND_AMOUNT_EXCEEDED_MSG",
  REFUND_AMOUNT_SUCCESS_MSG: "REFUND_AMOUNT_SUCCESS_MSG",
  REFUND_AMOUNT_ERROR_MSG: "REFUND_AMOUNT_ERROR_MSG",
  VEHICLE_ASSIGNMENT_SUCCESS: "VEHICLE_ASSIGNMENT_SUCCESS",
  VEHICLE_ASSIGNMENT_FAIL: "VEHICLE_ASSIGNMENT_FAIL",
  USERS_DEACTIVATED: "USERS_DEACTIVATED",
  ERROR_DEALER_APPLICATION_UPDATE: "ERROR_DEALER_APPLICATION_UPDATE",
  PKG_DEACTIVATION_SUCCESS: "PKG_DEACTIVATION_SUCCESS",
  PKG_ACTIVATION_SUCCESS: "PKG_ACTIVATION_SUCCESS",
  MSG_INSPECTION_APPROVED: "MSG_INSPECTION_APPROVED",
  MSG_INSPECTION_REJECTED: "MSG_INSPECTION_REJECTED",
  SOME_VEHICLE_NOT_VERIFIED: "SOME_VEHICLE_NOT_VERIFIED",
  SOME_VEHICLE_REJECTED_IMAGES_NOT_UPDATED:
    "SOME_VEHICLE_REJECTED_IMAGES_NOT_UPDATED",
  VEHICLE_LISTED_ERROR_MSG: "VEHICLE_LISTED_ERROR_MSG",
  PROMOTION_SAVE_SUCCESS: "PROMOTION_SAVE_SUCCESS",
  PROMOTION_SAVE_ERROR: "PROMOTION_SAVE_ERROR",
  PROMOTION_DELETE_SUCCESS: "PROMOTION_DELETE_SUCCESS",
  PROMOTION_DELETE_ERROR: "PROMOTION_DELETE_ERROR",
  FEATURE_VEHICLE_SUCCESS: "FEATURE_VEHICLE_SUCCESS",
  FEATURE_VEHICLE_FAILURE: "FEATURE_VEHICLE_FAILURE",
  FEATURE_COUNT_LIMIT_EXCEEDED: "FEATURE_COUNT_LIMIT_EXCEEDED",
  FEATURE_VEHICLE_VEHICLE_NOT_EXIST_IN_PACKAGESUBSCRIPTIONITEM:
    "FEATURE_VEHICLE_VEHICLE_NOT_EXIST_IN_PACKAGESUBSCRIPTIONITEM",
  FEATURED_VEHICLE_LISTING_SUBSCRIPTIONITEM_NOT_FOUND:
    "FEATURED_VEHICLE_LISTING_SUBSCRIPTIONITEM_NOT_FOUND",
  FEATURED_VEHICLE_LISTING_REMOVED_SUCCESS:
    "FEATURED_VEHICLE_LISTING_REMOVED_SUCCESS",
  FEATURED_VEHICLE_LISTING_REMOVED_FAILURE:
    "FEATURED_VEHICLE_LISTING_REMOVED_FAILURE",
  FEATURE_VEHICLE_ERROR_MSG: "FEATURE_VEHICLE_ERROR_MSG",
  VALIDATE_PACKAGE_SUBSCRIPTION_FOR_FEATURE_ERROR_MSG:
    "VALIDATE_PACKAGE_SUBSCRIPTION_FOR_FEATURE_ERROR_MSG",
  PACKAGE_SUBSCRIPTION_FOR_FEATURE_VALID:
    "PACKAGE_SUBSCRIPTION_FOR_FEATURE_VALID",
  PACKAGE_SUBSCRIPTION_FOR_FEATURE_NOT_VALID:
    "PACKAGE_SUBSCRIPTION_FOR_FEATURE_NOT_VALID",
  APPROVE_CHANGE_REQUEST: "APPROVE_CHANGE_REQUEST",
  REJECT_CHANGE_REQUEST: "REJECT_CHANGE_REQUEST",
  CREDIT_COUNT_LIMIT_OVER: "CREDIT_COUNT_LIMIT_OVER",
  QC_STATUS_UPDATE_SUCCESS: "QC_STATUS_UPDATE_SUCCESS",
  QC_STATUS_UPDATE_FAILED: "QC_STATUS_UPDATE_FAILED",
  VEHICLE_ALREADY_PROCEED: "VEHICLE_ALREADY_PROCEED",
  ERROR_CAN_NOT_EDIT_INSPECTION: "ERROR_CAN_NOT_EDIT_INSPECTION",
  ALTERNATEEMAIL_ALREADY_EXISTS: "ALTERNATEEMAIL_ALREADY_EXISTS",
  ALTERNATEMOBILENUMBER_ALREADY_EXISTS: "ALTERNATEMOBILENUMBER_ALREADY_EXISTS",
  PROFILE_REQUEST_NOT_APPROVED: "PROFILE_REQUEST_NOT_APPROVEDd",
  REJECT_PROFILE_REQUEST_NOT_SUCESSFUL: "REJECT_PROFILE_REQUEST_NOT_SUCESSFUL",
  FEATURE_UPDATE_SUCCESS: "FEATURE_UPDATE_SUCCESS",
  UPDATEDEALERVEHICLE_SUCCESS: "UPDATEDEALERVEHICLE_SUCCESS",
  MAKEMODELTRIM_COMBINATION_ALREADY_EXISTS:
    "MAKEMODELTRIM_COMBINATION_ALREADY_EXISTS",
  PKG_CONFIG_DEACTIVATION_SUCCESS: "PKG_CONFIG_DEACTIVATION_SUCCESS",
  PKG_CONFIG_ACTIVATION_SUCCESS: "PKG_CONFIG_ACTIVATION_SUCCESS",
  B2C_PACKAGE_ACTIVATE_SUCCESS: "B2C_PACKAGE_ACTIVATE_SUCCESS",
  B2C_PACKAGE_DEACTIVATE_SUCCESS: "B2C_PACKAGE_DEACTIVATE_SUCCESS",
  B2C_PACKAGECONFIGURATION_STATUS_ACTIVATE_SUCCESS:
    "B2C_PACKAGECONFIGURATION_STATUS_ACTIVATE_SUCCESS",
  B2C_PACKAGECONFIGURATION_STATUS_DEACTIVATE_SUCCESS:
    "B2C_PACKAGECONFIGURATION_STATUS_DEACTIVATE_SUCCESS",
  B2C_PACKAGECONFIGURATION_STATUS_ACTIVATE_FAILURE:
    "B2C_PACKAGECONFIGURATION_STATUS_ACTIVATE_FAILURE",
  B2C_PACKAGECONFIGURATION_STATUS_DEACTIVATE_FAILURE:
    "B2C_PACKAGECONFIGURATION_STATUS_DEACTIVATE_FAILURE",
  PKG_CONFIG_DEACTIVATION_FAILURE: "PKG_CONFIG_DEACTIVATION_FAILURE",
  PKG_CONFIG_ACTIVATION_FAILURE: "PKG_CONFIG_ACTIVATION_FAILURE",
  B2C_PACKAGECONFIGURATION_STATUS_DELETE_SUCCESS:
    "B2C_PACKAGECONFIGURATION_STATUS_DELETE_SUCCESS",
  SERVICE_DEACTIVATE_SUCCESS: "SERVICE_DEACTIVATE_SUCCESS",
  SERVICE_ACTIVATE_SUCCESS: "SERVICE_ACTIVATE_SUCCESS",
  SERVICE_CONFIGURATION_STATUS_DEACTIVATE_SUCCESS:
    "SERVICE_CONFIGURATION_STATUS_DEACTIVATE_SUCCESS",
  SERVICE_CONFIGURATION_STATUS_ACTIVATE_SUCCESS:
    "SERVICE_CONFIGURATION_STATUS_ACTIVATE_SUCCESS",
  SERVICE_CONFIGURATION_STATUS_DEACTIVATE_FAILURE:
    "SERVICE_CONFIGURATION_STATUS_DEACTIVATE_FAILURE",
  SERVICE_CONFIGURATION_STATUS_ACTIVATE_FAILURE:
    "SERVICE_CONFIGURATION_STATUS_ACTIVATE_FAILURE",
  SERVICE_CONFIGURATION_STATUS_DELETE_SUCCESS:
    "SERVICE_CONFIGURATION_STATUS_DELETE_SUCCESS",
  SERVICE_CONFIGURATION_ERROR_MSG: "SERVICE_CONFIGURATION_ERROR_MSG",
  SERVICE_CONFIGURATION_SUCCESS_MSG: "SERVICE_CONFIGURATION_SUCCESS_MSG",
  SERVICE_CONFIGURATION_UPDATE_ERROR_MSG:
    "SERVICE_CONFIGURATION_UPDATE_ERROR_MSG",
  SERVICE_CONFIGURATION_UPDATE_SUCCESS_MSG:
    "SERVICE_CONFIGURATION_UPDATE_SUCCESS_MSG",
  PACKAGE_CONFIGURATION_STATUS_ACTIVATE_SUCCESS:
    "PACKAGE_CONFIGURATION_STATUS_ACTIVATE_SUCCESS",
  PACKAGE_CONFIGURATION_STATUS_ACTIVATE_FAILURE:
    "PACKAGE_CONFIGURATION_STATUS_ACTIVATE_FAILURE",
  PACKAGE_CONFIGURATION_STATUS_DEACTIVATE_FAILURE:
    "PACKAGE_CONFIGURATION_STATUS_DEACTIVATE_FAILURE",
  PACKAGE_CONFIGURATION_STATUS_DEACTIVATE_SUCCESS:
    "PACKAGE_CONFIGURATION_STATUS_DEACTIVATE_SUCCESS",
  PACKAGE_CONFIGURATION_STATUS_DELETE_SUCCESS:
    "PACKAGE_CONFIGURATION_STATUS_DELETE_SUCCESS",
  PACKAGE_CONFIGURATION_STATUS_DELETE_FAILURE:
    "PACKAGE_CONFIGURATION_STATUS_DELETE_FAILURE",
  SERVICE_SEQUENCE_UPDATE_SUCCESS: "SERVICE_SEQUENCE_UPDATE_SUCCESS",
  SERVICE_SEQUENCE_UPDATE_FAILURE: "SERVICE_SEQUENCE_UPDATE_FAILURE",
  DUPLICATE_B2CPACKAGENAME: "DUPLICATE_B2CPACKAGENAME",
  DUPLICATE_SERVICENAME: "DUPLICATE_SERVICENAME",
  SERVICE_CREATION_ERROR_MSG: "SERVICE_CREATION_ERROR_MSG",
  PROMOTION_DEACTIVATION_SUCCESS: "PROMOTION_DEACTIVATION_SUCCESS",
  PROMOTION_ACTIVATION_SUCCESS: "PROMOTION_ACTIVATION_SUCCESS",
  PROMOTION_UPDATE_SUCCESS: "PROMOTION_UPDATE_SUCCESS",
  PROMOTION_UPDATE_FAILURE: "PROMOTION_UPDATE_FAILURE",
  SERVICE_DELETE_SUCCESS: "SERVICE_DELETE_SUCCESS",
  B2C_PACKAGE_DELETE_SUCCESS: "B2C_PACKAGE_DELETE_SUCCESS",
  B2C_PACKAGE_ACTIVATE_FAILURE: "B2C_PACKAGE_ACTIVATE_FAILURE",
  B2C_PACKAGE_DEACTIVATE_FAILURE: "B2C_PACKAGE_DEACTIVATE_FAILURE",
  B2C_PACKAGE_DELETE_FAILURE: "B2C_PACKAGE_DELETE_FAILURE",
  SAVE_B2C_SERVICE_ATTRIBUTE_MAPPING_SUCCESS:
    "SAVE_B2C_SERVICE_ATTRIBUTE_MAPPING_SUCCESS",
  PROMOTION_SAVE_FAILURE_DUPLICATE_CODE:
    "PROMOTION_SAVE_FAILURE_DUPLICATE_CODE",
  PROMOTION_UPDATE_FAILURE_DUPLICATE_CODE:
    "PROMOTION_UPDATE_FAILURE_DUPLICATE_CODE",
  VEHICLEPROFILE_ALREADY_EXISTS: "VEHICLEPROFILE_ALREADY_EXISTS",
  DOWNLOAD_XML_ERROR_MSG: "DOWNLOAD_XML_ERROR_MSG",
  ENTERED_AMOUNT_GREATER_THAN_ITEM_AMOUNT:
    "ENTERED_AMOUNT_GREATER_THAN_ITEM_AMOUNT",
  EMAIL_UPDATED_SUCCESSFULLY: "EMAIL_UPDATED_SUCCESSFULLY",
  ERROR_EMAIL_UPDATE: "ERROR_EMAIL_UPDATE",
  EMAIL_STATUS_UPDATED_SUCCESSFULLY: "EMAIL_STATUS_UPDATED_SUCCESSFULLY",
  ERROR_EMAIL_STATUS_UPDATE: "ERROR_EMAIL_STATUS_UPDATE",
  SMS_UPDATED_SUCCESSFULLY: "SMS_UPDATED_SUCCESSFULLY",
  ERROR_SMS_UPDATE: "ERROR_SMS_UPDATE",
  ALERT_NOTIFICATION_UPDATED_SUCCESSFULLY:
    "ALERT_NOTIFICATION_UPDATED_SUCCESSFULLY",
  ERROR_ALERT_NOTIFICATION_UPDATE: "ERROR_ALERT_NOTIFICATION_UPDATE",
  WHATSAPP_NOTIFICATION_UPDATED_SUCCESSFULLY:
    "WHATSAPP_NOTIFICATION_UPDATED_SUCCESSFULLY",
  ERROR_WHATSAPP_NOTIFICATION_UPDATE: "ERROR_WHATSAPP_NOTIFICATION_UPDATE",
  MOBILE_NOTIFICATION_UPDATED_SUCCESSFULLY:
    "MOBILE_NOTIFICATION_UPDATED_SUCCESSFULLY",
  ERROR_MOBILE_NOTIFICATION_UPDATE: "ERROR_MOBILE_NOTIFICATION_UPDATE",
  SEND_PREVIEW_SUCCESSFULLY: "SEND_PREVIEW_SUCCESSFULLY",
  ERROR_SEND_PREVIEW: "ERROR_SEND_PREVIEW",
  LBL_VEHICLE_DOCUMENTS: "LBL_VEHICLE_DOCUMENTS",
  UPDATE_VEHICLEPROFILE_STATUS_FAILED_PROFILE_HAVE_LISTEDVEHICALE:
    "UPDATE_VEHICLEPROFILE_STATUS_FAILED_PROFILE_HAVE_LISTEDVEHICALE",
  MAKE_ALREADY_EXISTS: "MAKE_ALREADY_EXISTS",
  MAKEMODEL_COMBINATION_ALREADY_EXISTS: "MAKEMODEL_COMBINATION_ALREADY_EXISTS",
  TRIM_COLOR_VARIANT_COMBINATION_ALREADY_EXISTS:
    "TRIM_COLOR_VARIANT_COMBINATION_ALREADY_EXISTS",
  VIDEO_DELETE_SUCCESS_MSG: "VIDEO_DELETE_SUCCESS_MSG",
  VIDEO_UPLOAD_SUCCESS_MSG: "VIDEO_UPLOAD_SUCCESS_MSG",
  VIDEO_UPLOAD_ERROR_MSG: "VIDEO_UPLOAD_ERROR_MSG",
  LBL_VEHICLE_VIDEOS: "LBL_VEHICLE_VIDEOS",
  COMMUNICATION_COLOR: "COMMUNICATION_COLOR",
};
