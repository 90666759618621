import { DateObject } from "react-multi-date-picker";
import {
  ImageArtifactKey,
  InspectionArtifactCategory,
} from "../../utilities/constants/global.constants";
import {
  SET_ROW_COUNT,
  SET_CURRENT_PAGE,
  SET_SORT_BY,
  SET_SELECTED_VEHICLE_STATUS,
  GET_VEHICLE_LIST,
  SET_VIEW_FOR_LIST,
  SET_VEHICLE_FOR_ASSIGN,
  SET_FILTER_OBJ,
  UPDATE_VEHICLE_LIST,
  SET_INSPECTION_REPORT,
  GET_VEHICLE_DETAILS_BY_VEHICLELISTINGID,
  GET_QC_REJECTION_STATUS,
  POST_QC_REJECTION_STATUS,
  POST_OVERALL_QC_STATUS,
  GET_VEHICLE_LISTING_DETAILS_BY_ID,
  GET_VEHICLE_LISTING_IMAGES_BY_ID_SUCCESS,
  GET_USER_SEARCH,
  GET_VEHICLE_DELETION_REASONS,
  DELETE_VEHICLE_SUCCESS,
  LISTING_VEHICLE_SUCCESS,
  SET_SELECTED_DMS_STATUS,
  FETCH_VEHICLE_DATA,
  FETCH_VEHICLE_DETAILS,
  GET_VEHICLE_DESCRIPTION,
  GET_VEHICLE_LISTING_IMAGES_BY_ID,
  UPDATE_VEHICLE_LISTING_IMAGES_STATUS,
  GET_QC_DETAILS,
  UPDATE_QC_DETAILS,
  CLEAR_VEHICLE_DETAILS,
  GET_MODEL_YEAR_DATA,
  GET_VEHICLE_MAKE,
  GET_VEHICLE_MODEL,
  GET_VEHICLE_TRIM,
  GET_SEARCH_RESULT,
  GET_LISTING_IMAGES,
  GET_LISTING_DOCUMENT,
  UPDATE_LISTING_IMAGES,
  GET_INSPECTOR_LISTING_IMAGES,
  GET_SPIN_CAR_360_IMAGES,
  GET_SPIN_CAR_IMAGES,
  GET_VEHICLE_PROFILE,
  GET_VEHICLE_PROFILE_DATA,
  SET_VEHICLE_PROFILE_FILTER,
  GET_ARTIFACT_BY_PROFILE_ID,
  GET_QC_REJECTION_ID,
  GET_MANUFACTURER_LISTING_IMAGES,
  GET_VEHICLE_BEFORE_AFTER_IMAGE,
  GET_APPOINTMENT_FILTER_DATA,
  SET_APPOINTMENT_FILTER,
  GET_APPOINTMENT_DETAILS,
  GET_DYNAMIC_REPORTS,
  GET_DYNAMIC_REPORTS_DATA,
  GET_VEHICLE_EBROCHURE,
  UPDATE_VEHICLE_EBROCHURE,
  GET_FAV_REPORTS,
  GET_COLOR_VARIANT,
  GET_COLOR_VARIANT_BY_ID,
  GET_TRIM_BY_ID,
  GET_VEHICLE_PROFILE_BY_ID,
  CLEAR_VEHICLE_PROFILE_DATA,
  SET_DEALS_FILTER_PAYLOAD,
  GET_DEAL_STATUS,
  GET_DEALER_VEHICLES_INFO,
  SET_DEALER_VEHICLES_FILTER,
  GET_ALL_REPORT_TYPES,
  GET_FULFILLED_BY_DATA,
  GET_IMPORTED_BY_DATA,
  SET_WISHLIST_LEADS_FILTER,
  GET_VEHICLE_LISTING_STATUS,
  SET_LEADS_FILTER_DETAILS,
  GET_VEHICLE_MAKE_V1,
  GET_VEHICLE_MODEL_V1,
  GET_VEHICLE_TRIM_V1,
} from "../types";

const initialState = {
  rowsCount: 6,
  currentPage: 1,
  sortBy: "UpdatedDate",
  vehicleSelectedStatus: [],
  vehicleView: "row",
  vehicleList: [],
  assignVehicle: [],
  filterObjData: [],
  reloadVehicleList: false,
  inspectionDetails: [],
  getVehicleDetails: [],
  getQcRejection: [],
  postQcRejection: "",
  listingImages: [],
  loading: true,
  postOverallQcStatus: "",
  vehicleDetails: null,
  userData: null,
  deletionReasons: [],
  vehicleSelectedDmsStatus: [],
  fetchVehicleList: false,
  fetchVehicleDetails: false,
  vehicleDescription: [],
  qcDetails: null,
  selectedQCDetails: null,
  allowEdit: false,
  modelData: [],
  vehicleMakeData: [],
  vehicleModelData: [],
  vehicleTrimData: [],
  searchData: null,
  listingImagesData: [],
  listingInspectorImagesData: [],
  listingDocumentData: null,
  spinCarImagesData: null,
  spinCar360ImagesData: null,
  vehicleProfileData: null,
  profileDataFilter: null,
  profileDataPayload: {
    makeId: null,
    modelId: null,
    trimId: null,
    modelYearId: null,
  },
  artifactProfileData: null,
  manuFacturerImagesData: [],
  qcRejectionStatusById: null,
  listingManufacturerImagesData: [],
  beforeAfterImageData: [],
  appointmentPayload: {
    appointmentCreatedDate: {
      max: new DateObject().format("YYYY-MM-DD"),
      min: new DateObject().format("YYYY-MM-DD"),
    },
    cityId: null,
    locationId: null,
  },
  appointmentFilter: {
    appointmentCreatedDate: {
      max: new DateObject().format("YYYY-MM-DD"),
      min: new DateObject().format("YYYY-MM-DD"),
    },
    cityId: null,
    locationId: null,
  },
  appointmentData: [],
  dynamicReports: [],
  dynamicReportsData: [],
  vehicleEBrochure: [],
  favReports: [],
  colorVariantData: [],
  colorVariantDataById: null,
  trimData: [],
  vehicleProfile: null,
  vehicleSpecs: [],
  dealerVehiclesData: [],
  vehiclesFilterData: null,
  dealsFilterPayload: null,
  getDealStatus: [],
  getAllReportTypes: [],
  fulFilledByData: [],
  importedByData: [],
  wishlistLeadsFilterData: null,
  listingStatusData: [],
  leadsFilterDetails: null,
};

export const vehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROW_COUNT:
      return {
        ...state,
        rowsCount: action.payload,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      };
    case SET_SELECTED_VEHICLE_STATUS:
      return {
        ...state,
        vehicleSelectedStatus: action.payload,
      };
    case GET_VEHICLE_LIST:
      return {
        ...state,
        vehicleList: action.payload,
        reloadVehicleList: false,
        fetchVehicleList: false,
      };
    case SET_VIEW_FOR_LIST:
      return {
        ...state,
        vehicleView: action.payload,
      };
    case SET_VEHICLE_FOR_ASSIGN:
      return {
        ...state,
        assignVehicle: action.payload,
      };
    case SET_FILTER_OBJ:
      return {
        ...state,
        filterObjData: action.payload,
      };
    case DELETE_VEHICLE_SUCCESS:
    case UPDATE_VEHICLE_LIST:
    case LISTING_VEHICLE_SUCCESS:
      return {
        ...state,
        reloadVehicleList: true,
      };
    case SET_INSPECTION_REPORT:
      return {
        ...state,
        inspectionDetails: action.payload,
      };
    case GET_VEHICLE_DETAILS_BY_VEHICLELISTINGID:
      return {
        ...state,
        getVehicleDetails: action.payload,
      };
    case GET_QC_REJECTION_STATUS:
      return {
        ...state,
        getQcRejection: action.payload,
      };
    case POST_QC_REJECTION_STATUS:
      return {
        ...state,
        postQcRejection: action.payload,
      };
    case GET_VEHICLE_LISTING_IMAGES_BY_ID:
      return {
        ...state,
        loading: true,
        listingImages: action.payload,
      };
    case GET_VEHICLE_LISTING_IMAGES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        listingImages: (action.payload || []).map((x) => {
          if (
            x.ArtifactCategory ===
              InspectionArtifactCategory.VehicleSupplierImage ||
            x.ArtifactTypeKey === ImageArtifactKey.DmgEvidenceBefore
          ) {
            x.IsQCApproved = true;
          }
          return x;
        }),
      };
    case UPDATE_VEHICLE_LISTING_IMAGES_STATUS:
      return {
        ...state,
        listingImages: action.payload.listingImages,
      };

    case POST_OVERALL_QC_STATUS:
      return {
        ...state,
        postOverallQcStatus: action.payload,
      };
    case GET_QC_DETAILS:
      return {
        ...state,
        qcDetails: action.payload,
        allowEdit: !action.payload.IsApproved,
        selectedQCDetails: action.payload,
      };
    case CLEAR_VEHICLE_DETAILS:
      return {
        ...state,
        loading: true,
        listingImages: [],
        allowEdit: false,
        vehicleDetails: null,
      };

    case UPDATE_QC_DETAILS:
      return {
        ...state,
        selectedQCDetails: action.payload.selectedQCDetails,
        qcDetails: { ...state.qcDetails, ...(action.payload.qcDetails || {}) },
      };
    case GET_VEHICLE_LISTING_DETAILS_BY_ID:
      return {
        ...state,
        vehicleDetails: action.payload,
        fetchVehicleDetails: false,
      };
    case GET_USER_SEARCH:
      return {
        ...state,
        userData: action.payload,
      };
    case GET_VEHICLE_DELETION_REASONS:
      return {
        ...state,
        deletionReasons: action.payload,
      };
    case SET_SELECTED_DMS_STATUS:
      return {
        ...state,
        vehicleSelectedDmsStatus: action.payload,
      };
    case FETCH_VEHICLE_DATA:
      return {
        ...state,
        fetchVehicleList: true,
      };
    case FETCH_VEHICLE_DETAILS:
      return {
        ...state,
        fetchVehicleDetails: true,
      };
    case GET_VEHICLE_DESCRIPTION:
      return {
        ...state,
        vehicleDescription: action.payload,
      };
    case GET_MODEL_YEAR_DATA:
      return {
        ...state,
        modelData: action.payload,
      };

    case GET_VEHICLE_MAKE:
      return {
        ...state,
        vehicleMakeData: action.payload,
      };
    case GET_VEHICLE_MAKE_V1:
      return {
        ...state,
        vehicleMakeData: action.payload,
      };
    case GET_SEARCH_RESULT:
      return {
        ...state,
        searchData: action.payload,
      };
    case GET_VEHICLE_MODEL:
      return {
        ...state,
        vehicleModelData: action.payload,
        // vehicleTrimData: [],
      };
    case GET_VEHICLE_MODEL_V1:
      return {
        ...state,
        vehicleModelData: action.payload,
      };
    case GET_VEHICLE_TRIM:
      return {
        ...state,
        vehicleTrimData: action.payload,
      };
    case GET_VEHICLE_TRIM_V1:
      return {
        ...state,
        vehicleTrimData: action.payload,
      };
    case GET_LISTING_IMAGES:
      return {
        ...state,
        listingImagesData: action.payload,
      };

    case GET_INSPECTOR_LISTING_IMAGES:
      return {
        ...state,
        listingInspectorImagesData: action.payload,
      };
    case GET_SPIN_CAR_IMAGES:
      return {
        ...state,
        spinCarImagesData: action.payload,
      };
    case GET_SPIN_CAR_360_IMAGES:
      return {
        ...state,
        spinCar360ImagesData: action.payload,
      };

    case UPDATE_LISTING_IMAGES:
      return {
        ...state,
        listingImagesData: [...state.listingImagesData, action.payload],
      };
    case GET_LISTING_DOCUMENT:
      return {
        ...state,
        listingDocumentData: action.payload,
      };
    case GET_VEHICLE_PROFILE:
      return {
        ...state,
        vehicleProfileData: action.payload,
      };
    case GET_VEHICLE_PROFILE_DATA:
      return {
        ...state,
        profileDataPayload: {
          ...state.profileDataPayload,
          ...action.payload,
        },
      };
    case SET_VEHICLE_PROFILE_FILTER:
      return {
        ...state,
        profileDataFilter: action.payload,
      };
    case GET_ARTIFACT_BY_PROFILE_ID:
      return {
        ...state,
        artifactProfileData: action.payload,
      };

    case GET_QC_REJECTION_ID:
      return {
        ...state,
        qcRejectionStatusById: action.payload,
      };
    case GET_MANUFACTURER_LISTING_IMAGES:
      return {
        ...state,
        listingManufacturerImagesData: action.payload,
      };
    case GET_VEHICLE_BEFORE_AFTER_IMAGE:
      return {
        ...state,
        beforeAfterImageData: action.payload,
      };
    case GET_VEHICLE_EBROCHURE:
      return {
        ...state,
        vehicleEBrochure: action.payload,
      };
    case UPDATE_VEHICLE_EBROCHURE:
      return {
        ...state,
        vehicleEBrochure: action.payload,
      };
    case GET_APPOINTMENT_FILTER_DATA:
      return {
        ...state,
        appointmentPayload: {
          ...state.appointmentPayload,
          ...action.payload,
        },
      };
    case SET_APPOINTMENT_FILTER:
      return {
        ...state,
        appointmentFilter: action.payload,
      };
    case GET_APPOINTMENT_DETAILS:
      return {
        ...state,
        appointmentData: action.payload,
      };
    case GET_DYNAMIC_REPORTS:
      return {
        ...state,
        dynamicReports: action.payload,
      };
    case GET_DYNAMIC_REPORTS_DATA:
      return {
        ...state,
        dynamicReportsData: action.payload,
      };
    case "UPDATE_VEHICLE_LISTING_DOCUMENT_STATUS":
      return {
        ...state,
        listingDocumentData: action.payload,
      };
    case GET_FAV_REPORTS:
      return {
        ...state,
        favReports: action.payload,
      };
    case GET_COLOR_VARIANT:
      return {
        ...state,
        colorVariantData: action.payload,
      };
    case GET_COLOR_VARIANT_BY_ID:
      return {
        ...state,
        colorVariantDataById: action.payload,
      };

    case GET_TRIM_BY_ID:
      return {
        ...state,
        trimData: action.payload,
      };

    case GET_VEHICLE_PROFILE_BY_ID:
      return {
        ...state,
        vehicleProfile: action.payload,
      };

    case CLEAR_VEHICLE_PROFILE_DATA:
      return {
        ...state,
        vehicleProfile: null,
        vehicleSpecs: [],
        artifactProfileData: null,
      };
    case GET_DEALER_VEHICLES_INFO:
      return {
        ...state,
        dealerVehiclesData: action.payload,
      };
    case SET_DEALER_VEHICLES_FILTER:
      return {
        ...state,
        vehiclesFilterData: action.payload,
      };
    case SET_DEALS_FILTER_PAYLOAD:
      return {
        ...state,
        dealsFilterPayload: action.payload,
      };
    case GET_DEAL_STATUS:
      return {
        ...state,
        getDealStatus: action.payload,
      };
    case GET_ALL_REPORT_TYPES:
      return {
        ...state,
        getAllReportTypes: action.payload,
      };
    case GET_FULFILLED_BY_DATA:
      return {
        ...state,
        fulFilledByData: action.payload,
      };
    case GET_IMPORTED_BY_DATA:
      return {
        ...state,
        importedByData: action.payload,
      };
    case SET_WISHLIST_LEADS_FILTER:
      return {
        ...state,
        wishlistLeadsFilterData: action.payload,
      };
    case GET_VEHICLE_LISTING_STATUS:
      return {
        ...state,
        listingStatusData: action.payload,
      };
    case SET_LEADS_FILTER_DETAILS:
      return {
        ...state,
        leadsFilterDetails: action.payload,
      };

    default:
      return state;
  }
};
