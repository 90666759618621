/**
 * Application Action Types
 */
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

/**
 * Global Action Types
 */
export const GET_HEADER_MENU = "GET_HEADER_MENU";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_CITY = "SET_CITY";
export const GET_HOMEPAGE_CITY_LIST = "GET_HOMEPAGE_CITY_LIST";
export const GET_SUMMARY_MENU = "GET_SUMMARY_MENU";
export const GET_CURRENCY = "GET_CURRENCY";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_STATE_BY_COUNTRY = "GET_STATE_BY_COUNTRY";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_LOCATION_NAME_ID = "GET_LOCATION_NAME_ID";
export const GET_LOCATION_TYPES = "GET_LOCATION_TYPES";
export const GET_LOCATION_NAME = "GET_LOCATION_NAME";
export const GET_ADDRESS_TYPES = "GET_ADDRESS_TYPES";
export const GET_WORKING_SECTOR = "GET_WORKING_SECTOR";
export const GET_WORKING_SECTOR_ID_SUCCESS = "GET_WORKING_SECTOR_ID_SUCCESS";
export const CLEAR_WORKING_SECTOR_EDIT_DETAILS =
  "CLEAR_WORKING_SECTOR_EDIT_DETAILS";
export const GET_BANK_DETAILS = "GET_BANK_DETAILS";
export const GET_BANK_DETAILS_BY_ID_SUCCESS = "GET_BANK_DETAILS_BY_ID_SUCCESS";
export const CLEAR_BANK_DETAIL = "CLEAR_BANK_DETAIL";
export const GET_ROLES = "GET_ROLES";
export const GET_LIST_HEADERS = "GET_LIST_HEADERS";
export const GET_LIST_ACTIVE_HEADERS = "GET_LIST_ACTIVE_HEADERS";
export const GET_LIST_PRIVILEGES = "GET_LIST_PRIVILEGES";
export const GET_LIST_ACTIVE_PRIVILEGES = "GET_LIST_ACTIVE_PRIVILEGES";
export const GET_PRIVILEGES = "GET_PRIVILEGES";
export const GET_PRODUCT_CATALOGUE = "GET_PRODUCT_CATALOGUE";
export const GET_PRODUCT_CATALOGUE_DATA = "GET_PRODUCT_CATALOGUE_DATA";

/**
 * Auth Action Types
 */
export const USER_LOGOUT = "USER_LOGOUT";

export const CHECK_AUTH_DETAILS = "CHECK_AUTH_DETAILS";
export const CHECK_AUTH_DETAILS_SUCCESS = "CHECK_AUTH_DETAILS_SUCCESS";
export const CHECK_AUTH_DETAILS_FAIL = "CHECK_AUTH_DETAILS_FAIL";

export const FETCH_AUTH_TOKEN = "FETCH_AUTH_TOKEN";
export const FETCH_AUTH_TOKEN_SUCCESS = "FETCH_AUTH_TOKEN_SUCCESS";
export const FETCH_AUTH_TOKEN_FAIL = "FETCH_AUTH_TOKEN_FAIL";

export const FETCH_REFRESH_AUTH_TOKEN = "FETCH_REFRESH_AUTH_TOKEN";
export const FETCH_REFRESH_AUTH_TOKEN_SUCCESS =
  "FETCH_REFRESH_AUTH_TOKEN_SUCCESS";
export const FETCH_REFRESH_AUTH_TOKEN_FAIL = "FETCH_REFRESH_AUTH_TOKEN_FAIL";
export const GET_USER_ROLE = "GET_USER_ROLE";
export const CHANGE_USER_ROLE = "CHANGE_USER_ROLE";
export const REFRESH_DASHBOARD_DATA = "REFRESH_DASHBOARD_DATA";
export const GET_APPOINTMENT_FILTER_DATA = "GET_APPOINTMENT_FILTER_DATA";
export const SET_APPOINTMENT_FILTER = "SET_APPOINTMENT_FILTER";
export const GET_APPOINTMENT_DETAILS = "GET_APPOINTMENT_DETAILS";

/**
 * Filter Action Types
 */
export const GET_FILTER_CITY_LIST = "GET_FILTER_CITY_LIST";
export const GET_FILTER_MAKE_MODEL = "GET_FILTER_MAKE_MODEL";
export const SET_OTHER_FILTER_DATA = "SET_OTHER_FILTER_DATA";
export const SET_FILTER_BODY_TYPE = "SET_FILTER_BODY_TYPE";
export const SET_FILTER_FUEL_TYPE = "SET_FILTER_FUEL_TYPE";
export const SET_VEHICLE_FILTERS = "SET_VEHICLE_FILTERS";
export const SET_SLIDER_DATA = "SET_SLIDER_DATA";
export const REMOVE_SELECTED_FILTER = "REMOVE_SELECTED_FILTER";
export const SET_SORT_API = "SET_SORT_API";
export const GET_MODEL_YEAR = "GET_MODEL_YEAR";
export const GET_MAKE = "GET_MAKE";
export const GET_MODEL_BY_MAKE = "GET_MODEL_BY_MAKE";
export const GET_STYLE = "GET_STYLE";
export const SET_DATE_FILTERS = "SET_DATE_FILTERS";
export const GET_DEALERS = "GET_DEALERS";

/**
 * Make Model Action Types
 */
export const GET_YEAR_DATA = "GET_YEAR_DATA";
export const GET_MAKE_DATA_BY_YEAR = "GET_MAKE_DATA_BY_YEAR";
export const GET_MODEL_BY_YEAR_MAKE = "GET_MODEL_BY_YEAR_MAKE";
export const GET_SPEC_DATA_BY_ALL = "GET_SPEC_DATA_BY_ALL";
export const ADD_VEHICLE_DETAILS = "ADD_VEHICLE_DETAILS";
export const GET_MAKE_BY_ID = "GET_MAKE_BY_ID";
export const GET_MODEL_BY_ID = "GET_MODEL_BY_ID";
export const CLEAR_MAKE_BY_ID_DETAILS = "CLEAR_MAKE__BY_ID_DETAILS";
export const CLEAR_MODEL_BY_ID_DETAILS = "CLEAR_MODEL__BY_ID_DETAILS";

/**
 * Master Data Configuration Action Types
 */
export const SET_ALL_CONFIGURATION_LIST = "SET_ALL_CONFIGURATION_LIST";
export const UPDATE_CONFIGURATION_DATA = "UPDATE_CONFIGURATION_DATA";
export const SET_MAX_VEHICLE_MILEAGE = "SET_MAX_VEHICLE_MILEAGE";
export const SET_ALLOWED_VEHICLE_AGE = "SET_ALLOWED_VEHICLE_AGE";
export const SET_VEHICLE_DELISTING_DAYS = "SET_VEHICLE_DELISTING_DAYS";
export const SET_INSPECTION_FEES = "SET_INSPECTION_FEES";
export const GET_CITY_LIST = "GET_CITY_LIST";
export const GET_MAKE_MODEL = "GET_MAKE_MODEL";
export const SET_MAX_PRICE_VEHICLE_FILTER = "SET_MAX_PRICE_VEHICLE_FILTER";
export const SET_MIN_PRICE_VEHICLE_FILTER = "SET_MIN_PRICE_VEHICLE_FILTER";
export const SET_MAX_ASKING_PRICE = "SET_MAX_ASKING_PRICE";
export const SET_MIN_ASKING_PRICE = "SET_MIN_ASKING_PRICE";
export const SET_CUSTOMER_SUPPORT_GENERAL_EMAIL =
  "SET_CUSTOMER_SUPPORT_GENERAL_EMAIL";
export const SET_CUSTOMER_SUPPORT_INSPECTION_EMAIL =
  "SET_CUSTOMER_SUPPORT_INSPECTION_EMAIL";
export const SET_CUSTOMER_EMAIL = "SET_CUSTOMER_EMAIL";
export const SET_BODY_AND_PAINT_CUSTOMER_SUPPORT_EMAIL_ID =
  "SET_BODY_AND_PAINT_CUSTOMER_SUPPORT_EMAIL_ID";
export const SET_QUALITY_CHECK_EMAIL_ADDRESS =
  "SET_QUALITY_CHECK_EMAIL_ADDRESS";
export const SET_AUTO_CARE_CUSTOMER_SUPPORT_EMAIL_ID =
  "SET_AUTO_CARE_CUSTOMER_SUPPORT_EMAIL_ID";
export const SET_AUTO_CARE_BOOK_NOW_TO_EMAIL_ID =
  "SET_AUTO_CARE_BOOK_NOW_TO_EMAIL_ID";
export const SET_BODY_AND_PAINT_BOOK_NOW_TO_EMAIL_ID =
  "SET_BODY_AND_PAINT_BOOK_NOW_TO_EMAIL_ID";
export const SET_CAREERS_EMAIL_ID = "SET_CAREERS_EMAIL_ID";
export const SET_IS_CR_ADDRESS_VERIFICATION = "SET_IS_CR_ADDRESS_VERIFICATION";
export const SET_IS_PAYFORT_ENABLED = "SET_IS_PAYFORT_ENABLED";
export const SET_MAX_EMI_PRICE_VEHICLE_FILTER =
  "SET_MAX_EMI_PRICE_VEHICLE_FILTER";
export const SET_MIN_EMI_PRICE_VEHICLE_FILTER =
  "SET_MIN_EMI_PRICE_VEHICLE_FILTER";
export const SET_ELM_OTP_LENGTH = "SET_ELM_OTP_LENGTH";
export const SET_ALLOWED_DAYS = "SET_ALLOWED_DAYS";
export const SET_AWS_LOW_THRESHOLD = "SET_AWS_LOW_THRESHOLD";
export const SET_AWS_HIGH_THRESHOLD = "SET_AWS_HIGH_THRESHOLD";
export const GET_DATE_TIME_FORMAT = "GET_DATE_TIME_FORMAT";
export const GET_FAQ_CATEGORIES = "GET_FAQ_CATEGORIES";
export const GET_FAQ_LIST = "GET_FAQ_LIST";
export const GET_TRANSLATIONS = "GET_TRANSLATIONS";
export const GET_TRANSLATION_FILTER_DATA = "GET_TRANSLATION_FILTER_DATA";
export const SET_TRANSLATION_FILTER = "SET_TRANSLATION_FILTER";
export const GET_TRANSLATION_STATUS = "GET_TRANSLATION_STATUS";

/**
 * QC Dashboard Action Types
 */
export const SET_VEHICLE_LISTING_HISTORY = "SET_VEHICLE_LISTING_HISTORY";
export const SET_QC_STATISTICS = "SET_QC_STATISTICS";
export const SET_QC_ANALYST_WITH_DETAILS = "SET_QC_ANALYST_WITH_DETAILS";
export const GET_QC_ANALYST_VEHICLES = "GET_QC_ANALYST_VEHICLES";

/**
 * Sales Person View Action Types
 */
export const GET_SALES_PERSON_DETAILS = "GET_SALES_PERSON_DETAILS";

/**
 * User Management Action Types
 */
export const GET_APPLICATION_ROLE_HIERARCHY = "GET_APPLICATION_ROLE_HIERARCHY";
export const GET_USER_STATISTIC = "GET_USER_STATISTIC";
export const GET_PETROMIN_ADMIN_USERS = "GET_PETROMIN_ADMIN_USERS";
export const CREATE_USER = "CREATE_USER";
export const GET_PROFILE_REQUEST_LIST = "GET_PROFILE_REQUEST_LIST";
export const GET_PO_ADMIN_STATISTIC = "GET_PO_ADMIN_STATISTIC";
export const GET_DMS_ADMIN_STATISTIC = "GET_DMS_ADMIN_STATISTIC";
export const GET_INSPECTOR_STATISTICS = "GET_INSPECTOR_STATISTICS";
export const GET_DEALER_CATEGORY = "GET_DEALER_CATEGORY";
export const GET_ACTIVE_CUSTOMERS = "GET_ACTIVE_CUSTOMERS";
export const GET_CUSTOMER_USER_STATISTIC = "GET_CUSTOMER_USER_STATISTIC";

/**
 * Vehicle Action Types
 */
export const SET_ROW_COUNT = "SET_ROW_COUNT";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_SORT_BY = "SET_SORT_BY";
export const SET_SELECTED_VEHICLE_STATUS = "SET_SELECTED_VEHICLE_STATUS";
export const GET_VEHICLE_LIST = "GET_VEHICLE_LIST";
export const SET_VIEW_FOR_LIST = "SET_VIEW_FOR_LIST";
export const SET_VEHICLE_FOR_ASSIGN = "SET_VEHICLE_FOR_ASSIGN";
export const SET_FILTER_OBJ = "SET_FILTER_OBJ";
export const UPDATE_VEHICLE_LIST = "UPDATE_VEHICLE_LIST";
export const SET_INSPECTION_REPORT = "SET_INSPECTION_REPORT";
export const GET_USER_SEARCH = "GET_USER_SEARCH";
export const GET_VEHICLE_DELETION_REASONS = "GET_VEHICLE_DELETION_REASONS";
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS";
export const SET_SELECTED_DMS_STATUS = "SET_SELECTED_DMS_STATUS";
export const FETCH_VEHICLE_DATA = "FETCH_VEHICLE_DATA";
export const LISTING_VEHICLE_SUCCESS = "LISTING_VEHICLE_SUCCESS";
export const FETCH_VEHICLE_DETAILS = "FETCH_VEHICLE_DETAILS";
export const GET_VEHICLE_DESCRIPTION = "GET_VEHICLE_DESCRIPTION";
export const GET_MODEL_YEAR_DATA = "GET_MODEL_YEAR_DATA";
export const GET_VEHICLE_MAKE = "GET_VEHICLE_MAKE";
export const GET_VEHICLE_MAKE_V1 = "GET_VEHICLE_MAKE_V1";
export const GET_SEARCH_RESULT = "GET_SEARCH_RESULT";
export const GET_VEHICLE_MODEL = "GET_VEHICLE_MODEL";
export const GET_VEHICLE_MODEL_V1 = "GET_VEHICLE_MODEL_V1";
export const GET_VEHICLE_TRIM = "GET_VEHICLE_TRIM";
export const GET_VEHICLE_TRIM_V1 = "GET_VEHICLE_TRIM_V1";
export const GET_LISTING_IMAGES = "GET_LISTING_IMAGES";
export const GET_LISTING_DOCUMENT = "GET_LISTING_DOCUMENT";
export const UPDATE_LISTING_IMAGES = "UPDATE_LISTING_IMAGES";
export const GET_INSPECTOR_LISTING_IMAGES = "GET_INSPECTOR_LISTING_IMAGES";
export const GET_SPIN_CAR_IMAGES = "GET_SPIN_CAR_IMAGES";
export const GET_SPIN_CAR_360_IMAGES = "GET_SPIN_CAR_360_IMAGES";
export const GET_VEHICLE_PROFILE = "GET_VEHICLE_PROFILE";
export const GET_VEHICLE_PROFILE_DATA = "GET_VEHICLE_PROFILE_DATA";
export const SET_VEHICLE_PROFILE_FILTER = "SET_VEHICLE_PROFILE_FILTER";
export const GET_ARTIFACT_BY_PROFILE_ID = "GET_ARTIFACT_BY_PROFILE_ID";
export const GET_VEHICLE_PROFILE_BY_ID = "GET_VEHICLE_PROFILE_BY_ID";
export const CLEAR_VEHICLE_PROFILE_DATA = "CLEAR_VEHICLE_PROFILE_DATA";
export const GET_MANUFACTURER_IMAGES = "GET_MANUFACTURER_IMAGES";
export const GET_QC_REJECTION_ID = "GET_QC_REJECTION_ID";
export const LOAD_MANUFACTURE_IMAGE_DATA = "LOAD_MANUFACTURE_IMAGE_DATA";
export const GET_MANUFACTURER_LISTING_IMAGES =
  "GET_MANUFACTURER_LISTING_IMAGES";
export const GET_VEHICLE_BEFORE_AFTER_IMAGE = "GET_VEHICLE_BEFORE_AFTER_IMAGE";
export const GET_DYNAMIC_REPORTS = "GET_DYNAMIC_REPORTS";
export const GET_DYNAMIC_REPORTS_DATA = "GET_DYNAMIC_REPORTS_DATA";
export const UPDATE_VEHICLE_LISTING_DOCUMENT_STATUS =
  "UPDATE_VEHICLE_LISTING_DOCUMENT_STATUS";
export const GET_FAV_REPORTS = "GET_FAV_REPORTS";
export const GET_COLOR_VARIANT = "GET_COLOR_VARIANT";
export const GET_COLOR_VARIANT_BY_ID = "GET_COLOR_VARIANT_BY_ID";
export const GET_TRIM_BY_ID = "GET_TRIM_BY_ID";
export const GET_ALL_MAKES = "GET_ALL_MAKES";
export const SET_DEALS_FILTER_PAYLOAD = "SET_DEALS_FILTER_PAYLOAD";
export const GET_DEAL_STATUS = "GET_DEAL_STATUS";
export const GET_DEALER_VEHICLES_INFO = "GET_DEALER_VEHICLES_INFO";
export const SET_DEALER_VEHICLES_FILTER = "SET_DEALER_VEHICLES_FILTER";
export const GET_ALL_REPORT_TYPES = "GET_ALL_REPORT_TYPES";
export const GET_FULFILLED_BY_DATA = "GET_FULFILLED_BY_DATA";
export const GET_IMPORTED_BY_DATA = "GET_IMPORTED_BY_DATA";
export const SET_WISHLIST_LEADS_FILTER = "SET_WISHLIST_LEADS_FILTER";
export const GET_VEHICLE_LISTING_STATUS = "GET_VEHICLE_LISTING_STATUS";
export const SET_LEADS_FILTER_DETAILS = "SET_LEADS_FILTER_DETAILS";

/**
 * Vehicle Details Action Types
 */
export const GET_VEHICLE_DETAILS_BY_VEHICLELISTINGID =
  "GET_VEHICLE_DETAILS_BY_VEHICLELISTINGID";
export const GET_QC_REJECTION_STATUS = "GET_QC_REJECTION_STATUS";
export const POST_QC_REJECTION_STATUS = "POST_QC_REJECTION_STATUS";
export const GET_VEHICLE_LISTING_IMAGES_BY_ID =
  "GET_VEHICLE_LISTING_IMAGES_BY_ID";
export const POST_OVERALL_QC_STATUS = "POST_OVERALL_QC_STATUS";
export const GET_VEHICLE_LISTING_DETAILS_BY_ID =
  "GET_VEHICLE_LISTING_DETAILS_BY_ID";
export const GET_VEHICLE_LISTING_IMAGES_BY_ID_SUCCESS =
  "GET_VEHICLE_LISTING_IMAGES_BY_ID_SUCCESS";
export const UPDATE_VEHICLE_LISTING_IMAGES_STATUS =
  "UPDATE_VEHICLE_LISTING_IMAGES_STATUS";
export const UPDATE_QC_DETAILS = "UPDATE_QC_DETAILS";
export const GET_QC_DETAILS = "GET_QC_DETAILS";
export const CLEAR_VEHICLE_DETAILS = "CLEAR_VEHICLE_DETAILS";
export const GET_VEHICLE_EBROCHURE = "GET_VEHICLE_EBROCHURE";
export const UPDATE_VEHICLE_EBROCHURE = "UPDATE_VEHICLE_EBROCHURE";

/**
 * Dealer Submit Form Action Types Types
 */
export const GET_CITY_LIST_BY_STATE = "GET_CITY_LIST_BY_STATE";
export const GET_MAKE_LIST = "GET_MAKE_LIST";
export const GET_STATE_LIST = "GET_STATE_LIST";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_DISTRICT_LIST = "GET_DISTRICT_LIST";

/**
 * DMS Dashboard Action Types
 */
export const SET_VEHICLE_QUERY_LIST = "SET_VEHICLE_QUERY_LIST";
export const SET_VEHICLE_QUERY_LIST_SUCCESS = "SET_VEHICLE_QUERY_LIST_SUCCESS";

/**
 * DMS User Management Action Types
 */
export const GET_GLOBAL_DATA_CITIES = "GET_GLOBAL_DATA_CITIES";
export const GET_DEALER_INFORMATION = "GET_DEALER_INFORMATION";
export const FETCH_DEALER_REJECTION_REASON = "FETCH_DEALER_REJECTION_REASON";
export const FETCH_DEALER_REJECTION_REASON_SUCCESS =
  "FETCH_DEALER_REJECTION_REASON_SUCCESS";
export const FETCH_DEALER_REJECTION_REASON_FAIL =
  "FETCH_DEALER_REJECTION_REASON_FAIL";
export const FETCH_PENDING_DEALER_APPLICATION =
  "FETCH_PENDING_DEALER_APPLICATION";
export const FETCH_PENDING_DEALER_APPLICATION_SUCCESS =
  "FETCH_PENDING_DEALER_APPLICATION_SUCCESS";
export const FETCH_PENDING_DEALER_APPLICATION_FAIL =
  "FETCH_PENDING_DEALER_APPLICATION_FAIL";
export const APPROVE_DEALER_APPLICATION = "APPROVE_DEALER_APPLICATION";
export const APPROVE_DEALER_APPLICATION_FAIL =
  "APPROVE_DEALER_APPLICATION_FAIL";
export const REJECT_DEALER_APPLICATION = "REJECT_DEALER_APPLICATION";
export const REJECT_DEALER_APPLICATION_FAIL = "REJECT_DEALER_APPLICATION_FAIL";
export const GET_PROFILE_DETAILS_BY_ID = "GET_PROFILE_DETAILS_BY_ID";
export const GET_PROFILE_CHANGE_REQ_LIST = "GET_PROFILE_CHANGE_REQ_LIST";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const FETCH_AUTH_TOKEN_ERROR = "FETCH_AUTH_TOKEN_ERROR";
export const GET_DEALER_SALES_USERS = "GET_DEALER_SALES_USERS";
export const SET_ROLE_ID = "SET_ROLE_ID";

/**
 * DMS Sales Person Dashboard types
 */
export const GET_SALES_PERSON_DASHBOARD_DETAILS =
  "GET_SALES_PERSON_DASHBOARD_DETAILS";
export const GET_PENDING_VEHICLE_ASSIGNED = "GET_PENDING_VEHICLE_ASSIGNED";
export const GET_PENDING_LEADS = "GET_PENDING_LEADS";

/**
 * Package Action Types
 */
export const GET_PACKAGES = "GET_PACKAGES";
export const GET_PACKAGE_DETAIL = "GET_PACKAGE_DETAIL";
export const GET_DURATION = "GET_DURATION";
export const GET_PACKAGE_SUBSCRIPTION_HISTORY =
  "GET_PACKAGE_SUBSCRIPTION_HISTORY";
export const GET_PACKAGE_LIST = "GET_PACKAGE_LIST";
export const GET_ACTIVE_SUBSCRIPTION_LIST = "GET_ACTIVE_SUBSCRIPTION_LIST";
export const GET_PACKAGE_CONFIG_VALUE = "GET_PACKAGE_CONFIG_VALUE";
export const GET_B2C_PACKAGES = "GET_B2C_PACKAGES";
export const GET_B2C_PACKAGE_DETAIL = "GET_B2C_PACKAGE_DETAIL";
export const GET_B2C_PACKAGES_BY_ID = "GET_B2C_PACKAGES_BY_ID";
export const GET_B2C_PACKAGE_CONFIG_DETAIL = "GET_B2C_PACKAGE_CONFIG_DETAIL";
export const SET_PACKAGE_DETAILS = "SET_PACKAGE_DETAILS";
export const SET_ASSOCIATE_SERVICES = "SET_ASSOCIATE_SERVICES";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";

/**
 * Services Action Types
 */
export const GET_SERVICES = "GET_SERVICES";
export const GET_SERVICES_DETAIL = "GET_SERVICES_DETAIL";
export const GET_SERVICE_PARAMETERS = "GET_SERVICE_PARAMETERS";
export const GET_SERVICE_DETAILS_BY_ID = "GET_SERVICE_DETAILS_BY_ID";
export const GET_SERVICE_CONFIG_DETAIL = "GET_SERVICE_CONFIG_DETAIL";

/**
 * Package Subscription Action Types
 */
export const GET_SHOPPING_CART = "GET_SHOPPING_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const FETCH_PACKAGES = "FETCH_PACKAGES";
export const CART_LOADING = "CART_LOADING";

/**
 * Invoice Action Types
 */
export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_STATUS = "GET_INVOICE_STATUS";
export const GET_INVOICE_DETAILS_BY_ID = "GET_INVOICE_DETAILS_BY_ID";
export const GET_CHARGE_DETAILS = "GET_CHARGE_DETAILS";
export const GET_PAYMENT_STATUS = "GET_PAYMENT_STATUS";
export const SET_INVOICE_API_DATA = "SET_INVOICE_API_DATA";
export const GET_INVOICE_DATA = "GET_INVOICE_DATA";
export const GET_QUOTATION = "GET_QUOTATION";
export const SET_QUOTATION_FILTER = "SET_QUOTATION_FILTER";
export const GET_QUOTATION_DATA = "GET_QUOTATION_DATA";
export const GET_QUOTATION_STATUS = "GET_QUOTATION_STATUS";
export const GET_QUOTATION_BY_ID = "GET_QUOTATION_BY_ID";
export const GET_QUOTATION_BY_ID_SUCCESS = "GET_QUOTATION_BY_ID_SUCCESS";
export const GET_QUOTATION_DOCUMENTS = "GET_QUOTATION_DOCUMENTS";
export const GET_QUOTATION_DOCUMENTS_SUCCESS =
  "GET_QUOTATION_DOCUMENTS_SUCCESS";
export const SET_SELECTED_STATUS = "SET_SELECTED_STATUS";
export const GET_QUOTATION_STATUS_ID = "GET_QUOTATION_STATUS_ID";
export const SET_SELECTED_TEMPLATE = "SET_SELECTED_TEMPLATE";

/**
 * INIT_LOAD
 */
export const INIT_LOAD_VEHICLE_VIEW = "INIT_LOAD_VEHICLE_VIEW";
export const UPDATE_MODEL_FILTER_DATA = "UPDATE_MODEL_FILTER_DATA";
export const UPDATE_USER_FILTER = "UPDATE_USER_FILTER";
export const UPDATE_SPECS_FILTER_DATA = "UPDATE_SPECS_FILTER_DATA";
export const CLEAR_USER_FILTER = "CLEAR_USER_FILTER";
export const FETCH_VEHICLES_STARTED = "FETCH_VEHICLES_STARTED";
export const FETCH_VEHICLES_ENDED = "FETCH_VEHICLES_ENDED";
export const LOAD_VEHICLES = "LOAD_VEHICLES";
export const UPDATE_META_DATA = "UPDATE_META_DATA";
export const UPDATE_INIT_STATUS_FILTER = "UPDATE_INIT_STATUS_FILTER";
export const UPDATE_STATUS_FILTER = "UPDATE_STATUS_FILTER";
export const GET_QC_STATUS_FILTER = "GET_QC_STATUS_FILTER";
export const FETCH_DEALER_DATA = "FETCH_DEALER_DATA";
export const FETCH_SALES_PERSON_DETAILS = "FETCH_SALES_PERSON_DETAILS";
export const FETCH_QC_DETAILS = "FETCH_QC_DETAILS";
export const UPDATE_SELECTED_VEHICLE = "UPDATE_SELECTED_VEHICLE";
export const UPDATE_SALES_PERSON_ASSIGN_TO_VALUE =
  "UPDATE_SALES_PERSON_ASSIGN_TO_VALUE";
export const UPDATE_QC_ASSIGN_TO_VALUE = "UPDATE_QC_ASSIGN_TO_VALUE";
export const CURRENT_QC_DMS_ASSIGN_TO_VALUE = "CURRENT_QC_DMS_ASSIGN_TO_VALUE";
export const SET_DELIST_DATA = "SET_DELIST_DATA";
export const SET_INSPECTION_VALUE = "SET_INSPECTION_VALUE";
export const GET_VEHICLE_DELETION_REASON = "GET_VEHICLE_DELETION_REASON";
export const SET_USER_SEARCH = "SET_USER_SEARCH";
export const CLEAR_METADATA = "CLEAR_METADATA";
export const FETCH_INITIALS_STARTED = "FETCH_INITIALS_STARTED";
export const FETCH_INITIALS_ENDED = "FETCH_INITIALS_ENDED";
export const UPDATE_SELECTED_VEHICLE_VIA_CHECKBOX =
  "UPDATE_SELECTED_VEHICLE_VIA_CHECKBOX";
export const UPDATE_LISTING_ID = "UPDATE_LISTING_ID";
export const UPDATE_LISTED_VEHICLE_CHECKED = "UPDATE_LISTED_VEHICLE_CHECKED";

/**
 * Promotion Action Types
 */
export const GET_PROMOTIONS = "GET_PROMOTIONS";
export const GET_PROMOTIONS_CRITERIA = "GET_PROMOTIONS_CRITERIA";
export const FETCH_PROMOTION_LIST = "FETCH_PROMOTION_LIST";
export const PROMOTION_TYPE = "PROMOTION_TYPE";
export const GET_PROMOTION_DETAILS = "GET_PROMOTION_DETAILS";
export const GET_PROMOTION_USAGE = "GET_PROMOTION_USAGE";
export const GET_PROMOTON_DATA = "GET_PROMOTON_DATA";

/* Header socialmedia data */

export const GET_SOCIAL_MEDIA_DATA = "GET_SOCIAL_MEDIA_DATA";
/* 
footer types
 */

export const GET_ABOUT_US = "GET_ABOUT_US";
export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";
export const GET_TERMS_CONDITIONS = "GET_TERMS_CONDITIONS";

/* 
Feedback action type
 */

export const GET_FEEDBACK_STATISTICS = "GET_FEEDBACK_STATISTICS";
export const GET_FEEDBACK_RATING_COUNT = "GET_FEEDBACK_RATING_COUNT";
export const GET_FEEDBACK_TRENDS = "GET_FEEDBACK_TRENDS";
export const GET_ALL_FEEDBACK = "GET_ALL_FEEDBACK";
export const FETCH_FEEDBACK_STARTED = "FETCH_FEEDBACK_STARTED";
export const FETCH_FEEDBACK_ENDED = "FETCH_FEEDBACK_ENDED";
export const UPDATE_FEEDBACK_META_DATA = "UPDATE_FEEDBACK_META_DATA";
export const GET_FEEDBACK_TYPE = "GET_FEEDBACK_TYPE";
export const SET_FEEDBACK_PAYLOAD = "SET_FEEDBACK_PAYLOAD";
export const POST_FEEDBACK = "POST_FEEDBACK";
export const GET_FEEDBACK_EMAIL_DATA = "GET_FEEDBACK_EMAIL_DATA";
export const SET_FEEDBACK_EMAIL_DATA = "SET_FEEDBACK_EMAIL_DATA";
export const SET_FEEDBACK_EMAIL_API_DATA = "SET_FEEDBACK_EMAIL_API_DATA";
export const SET_FEEDBACK_STATUS = "SET_FEEDBACK_STATUS";

/* 
Notification action type
 */

export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
export const GET_NOTIFICATION_UNREAD_COUNT = "GET_NOTIFICATION_UNREAD_COUNT";

/**
 * CMS Configuration type
 */
export const GET_CMS_CONFIGURATION = "GET_CMS_CONFIGURATION";
export const UPDATE_CMS_CONFIGURATION = "UPDATE_CMS_CONFIGURATION";
export const GET_CMS_TEXT_CONFIGURATION = "GET_CMS_TEXT_CONFIGURATION";
export const GET_CMS_TEXT_CONFIGURATION_KEY = "GET_CMS_TEXT_CONFIGURATION_KEY";

/**
 * Email Notifcation type
 */
export const GET_EMAIL_NOTIFICATION = "GET_EMAIL_NOTIFICATION";
export const GET_EMAIL_NOTIFICATION_BY_ID = "GET_EMAIL_NOTIFICATION_BY_ID";
export const GET_EMAIL_COMMON_TEMPLATE = "GET_EMAIL_COMMON_TEMPLATE";
export const GET_ALERT_NOTIFICATION = "GET_ALERT_NOTIFICATION";
export const GET_ALERT_NOTIFICATION_BY_ID = "GET_ALERT_NOTIFICATION_BY_ID";
export const GET_SMS_NOTIFICATION = "GET_SMS_NOTIFICATION";
export const GET_SMS_NOTIFICATION_BY_ID = "GET_SMS_NOTIFICATION_BY_ID";
export const GET_WHATSAPP_NOTIFICATION = "GET_WHATSAPP_NOTIFICATION";
export const GET_WHATSAPP_NOTIFICATION_BY_ID =
  "GET_WHATSAPP_NOTIFICATION_BY_ID";
export const GET_MOBILE_NOTIFICATION = "GET_MOBILE_NOTIFICATION";
export const GET_MOBILE_NOTIFICATION_BY_ID = "GET_MOBILE_NOTIFICATION_BY_ID";
/**
 * vas type
 */
export const SET_WARRANTY_VEHICLE_DATA = "SET_WARRANTY_VEHICLE_DATA";
export const OPEN_WARRANTY_POP_UP = "OPEN_WARRANTY_POP_UP";
export const SET_STANDALONE_WARRANTY_VEHICLE_DATA =
  "SET_STANDALONE_WARRANTY_VEHICLE_DATA";
export const GET_GLOBAL_DATA_VAS_CITIES = "GET_GLOBAL_DATA_VAS_CITIES";
