import { API, API2 } from "../../middleware";
import {
  GET_YEAR_DATA,
  GET_SPEC_DATA_BY_ALL,
  GET_MODEL_BY_YEAR_MAKE,
  GET_MAKE_DATA_BY_YEAR,
  ADD_VEHICLE_DETAILS,
  GET_MAKE_BY_ID,
  GET_MODEL_BY_ID,
  CLEAR_MODEL_BY_ID_DETAILS,
  CLEAR_MAKE_BY_ID_DETAILS,
  GET_ALL_MAKES,
} from "../types";
import { hideLoader, showLoader } from "./applicationAction";

export const setYears = (data) => ({
  type: GET_YEAR_DATA,
  payload: data,
});

export const setMakes = (data) => ({
  type: GET_MAKE_DATA_BY_YEAR,
  payload: data,
});

export const setModels = (data) => ({
  type: GET_MODEL_BY_YEAR_MAKE,
  payload: data,
});

export const setStyles = (data) => ({
  type: GET_SPEC_DATA_BY_ALL,
  payload: data,
});

export const setVehicleDetails = (data) => ({
  type: ADD_VEHICLE_DETAILS,
  payload: data,
});
export const setModelByIDData = (data) => ({
  type: GET_MODEL_BY_ID,
  payload: data,
});

export const setMakeByIDData = (data) => ({
  type: GET_MAKE_BY_ID,
  payload: data,
});

export function getYearModelData() {
  return (dispatch) => {
    return API.get("/api/Vehicle/modelyear")
      .then((response) => {
        dispatch(setYears(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getMakeDataByYear(lan, id) {
  return (dispatch) => {
    return API.get("/api/Vehicle/makesbymodelyearcode", {
      params: { languageId: lan, modelYearCode: id },
    })
      .then((response) => {
        dispatch(setMakes(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getModelByYearMake(lan, yid, mid) {
  return (dispatch) => {
    return API.get("/api/Vehicle/modelsbymodelyearcodemakecode", {
      params: {
        languageId: lan,
        modelYearCode: yid,
        makeCode: mid,
      },
    })
      .then((response) => {
        dispatch(setModels(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getSpecByAll(yid, mkid, moid) {
  return (dispatch) => {
    return API.get("/api/Vehicle/spec", {
      params: {
        modelYearCode: yid,
        makeCode: mkid,
        modelCode: moid,
      },
    })
      .then((response) => {
        dispatch(setStyles(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getVehicleDescription(yid, mkid, mid, sid) {
  return () => {
    return API.get("api/Vehicle/descriptionbymymmt", {
      params: {
        modelYearCode: yid,
        makeCode: mkid,
        modelCode: mid,
        specCode: sid,
      },
    });
  };
}

export const addVehicleDetails = (data) => async (dispatch) => {
  try {
    const response = await API.post("/api/Vehicle/vehicledetails", data);
    dispatch(setVehicleDetails(response));
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadExcelFile = (data) => async () => {
  try {
    const response = await API.post("/api/Vehicle/bulkuploadartifact", data);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const saveMake = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("/api/Vehicle/upsertmake", data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const saveMakeV1 = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API2.post("/master-data/upsert-make", data);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  } finally {
    dispatch(hideLoader());
  }
};

export const saveModel = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("/api/Vehicle/upsertmodel", data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const saveModelV1 = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API2.post("/master-data/upsert-model", data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

export const getMakeByMakeId = (makeId) => async (dispatch) => {
  try {
    const response = await API.get("/api/Vehicle/makebyid", {
      params: { makeId: makeId },
    });
    dispatch({ type: GET_MAKE_BY_ID, payload: response.data });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMakeByMakeIdV1 = (makeId) => async (dispatch) => {
  try {
    const response = await API2.get(`/master-data/make/${makeId}`);
    dispatch({ type: GET_MAKE_BY_ID, payload: response.data?.data || {} });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getModelByModelId = (modelId) => async (dispatch) => {
  try {
    const response = await API.get("/api/Vehicle/modelbyid", {
      params: { modelId: modelId },
    });
    dispatch(setModelByIDData(response.data));
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getModelByModelIdV1 = (modelId) => async (dispatch) => {
  try {
    const response = await API2.get(`/master-data/model/${modelId}`);
    dispatch(setModelByIDData(response.data?.data));
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const cleanMakeByIdData = () => async (dispatch) => {
  dispatch({ type: CLEAR_MAKE_BY_ID_DETAILS });
};

export const clearModelData = () => async (dispatch) => {
  dispatch({ type: CLEAR_MODEL_BY_ID_DETAILS });
};

export const getAllMakes = (languageId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/vehicle/makes?languageId=${languageId}`
    );
    dispatch(hideLoader());
    dispatch({ type: GET_ALL_MAKES, payload: response.data });
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getMakeDistributor = (languageId, makeId) => async () => {
  try {
    const response = await API.get(
      `/api/vehicle/MakeDistributors?LanguageId=${languageId}&MakeId=${makeId}`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const saveMakeDistributor = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("/api/vehicle/MakeDistributor", data);
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMakeImporter = (languageId, makeId) => async () => {
  try {
    const response = await API.get(
      `/api/vehicle/makeimporters?languageId=${languageId}&makeId=${makeId}`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const saveMakeImporter = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("/api/vehicle/makeimporter", data);
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
