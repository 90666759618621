export const ApplicationRoles = {
  PETROMIN_ADMIN: "PetrominAdmin",
  CRM_ADMIN: "CRMAdmin",
  CRM_SALESPERSON: "CRMSalesPerson",
  QC_ADMIN: "QCAdmin",
  QC_ANALYST: "QCAnalyst",
  DMS_ADMIN: "DMSAdmin",
  DMS_SALESPERSON: "DMSSalesPerson",
  INSPECTOR: "Inspector",
  CUSTOMER: "Customer",
  RVA_ANALYST: "RVAnalyst",
  CUSTOMER_CARE_ADMIN: "CustomerCareAdmin",
  TRANSLATOR: "Translator",
  WARRANTY: "Warranty",
  FINPAL_EXECUTIVE: "FinpalExecutive",
  SALES_ADMIN: "SalesAdmin",
  SALES_EXECUTIVE: "SalesExecutive",
  CUSTOMER_CARE_EXECUTIVE: "CustomerCareExecutive",
  WAYPOINT_INSPECTOR: "WaypointInspector",
};

export const UserRoleTypes = {
  PetrominAdmin: {
    Key: ApplicationRoles.PETROMIN_ADMIN,
    DisplayValue: "Petromin Admin",
  },
  CRMAdmin: {
    Key: ApplicationRoles.CRM_ADMIN,
    DisplayValue: "CRMAdmin",
  },
  CRMSalesPerson: {
    Key: ApplicationRoles.CRM_SALESPERSON,
    DisplayValue: "CRM Sales Person",
  },
  QCAdmin: {
    Key: ApplicationRoles.QC_ADMIN,
    DisplayValue: "QC Admin",
  },
  QCAnalyst: { Key: ApplicationRoles.QC_ANALYST, DisplayValue: "QC Analyst" },
};

export const ApplicationPrivileges = {
  VehicleView: "VehicleView",
  VehicleDetailsView: "VehicleDetailsView",
  VehicleApproveRejectView: "VehicleApproveRejectView",
  VehicleHistoryView: "VehicleHistoryView",
  DMSLeadsView: "DMSLeadsView",
  CRMLeadsView: "CRMLeadsView",
  UsersView: "UsersView",
  CreateUsersView: "CreateUsersView",
  UpdateUsersView: "UpdateUsersView",
  DMSUsersView: "DMSUsersView",
  DMSCreateUsersView: "DMSCreateUsersView",
  DMSUpdateUsersView: "DMSUpdateUsersView",
  ProfileChangeRequestView: "ProfileChangeRequestView",
  ChangeProfileView: "ChangeProfileView",
  BulkUploadView: "BulkUploadView",
  BulkUploadReportView: "BulkUploadReportView",
  ConfigurationView: "ConfigurationView",
  CRMView: "CRMView",
  DMSApplicationView: "DMSApplicationView",
  FeedbackView: "FeedbackView",
  InspectionFeesView: "InspectionFeesView",
  VehicleDeListingView: "VehicleDeListingView",
  TopCitiesView: "TopCitiesView",
  TopBrandsView: "TopBrandsView",
  VehicleAcceptanceCriteriaView: "VehicleAcceptanceCriteriaView",
  SalesPersonView: "SalesPersonView",
  PackagesView: "PackagesView",
  QueriesView: "QueriesView",
  AddPackageView: "AddPackageView",
  EditPackageView: "EditPackageView",
  ViewProfile: "ViewProfile",
  SaveVehicleProfile: "SaveVehicleProfile",
  ViewVehicleProfile: "ViewVehicleProfile",
  ViewTranslations: "ViewTranslations",
  ViewWarranties: "ViewWarranties",
  ViewConfigurations: "ViewConfigurations",
  ViewServices: "ViewServices",
  ViewDealerPackages: "ViewDealerPackages",
  ViewSelfListingPackages: "ViewSelfListingPackages",
  ViewPromotion: "ViewPromotion",
  ViewBannerImage: "ViewBannerImage",
  ViewListValues: "ViewListValues",
  ViewTemplates: "ViewTemplates",
  ViewQuotationStatus: "ViewQuotationStatus",
  ViewQCRejectionReasons: "ViewQCRejectionReasons",
  ViewLocationDetails: "ViewLocationDetails",
  ViewWorkingSector: "ViewWorkingSector",
  ViewBankDetails: "ViewBankDetails",
  ViewRoles: "ViewRoles",
  ViewPrivileges: "ViewPrivileges",
  ViewEmailTemplates: "ViewEmailTemplates",
  ViewSMSNotification: "ViewSMSNotification",
  ViewAlertNotification: "ViewAlertNotification",
  ViewWhatsappNotification: "ViewWhatsappNotification",
  ViewMobileNotification: "ViewMobileNotification",
  ViewFAQs: "ViewFAQs",
  CustomerFeedbacks: "CustomerFeedbacks",
  ViewSupplier: "ViewSupplier",
  ViewProductCatalogue: "ViewProductCatalogue",
  ViewDeals: "ViewDeals",
  ViewVehiclesButtons: "ViewVehiclesButtons",
  SoldVehicle: "SoldVehicle",
  CreateInvoice: "CreateInvoice",
  CreateQuotation: "CreateQuotation",
  ViewSegment: "ViewSegment",
  Specification: "Specification",
  PurchaseWarranty: "PurchaseWarranty",
  ViewAppointments: "ViewAppointments",
  ViewBatches: "ViewBatches",
  ViewAllWishlistLeads: "ViewAllWishlistLeads",
  ViewTransactions: "ViewTransactions",
  ViewReports: "ViewReports",
};
